import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Tab,
  Tabs,
  Typography,
  useTheme,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import {
  usersSelectorList,
  usersSelectorLoadingState,
} from "../../../../selectors/userSelector";
// import { UserModel } from "../../../../@types/user-model";
import ReusableTable from "../../../../components/reusable-table";
import { CustomColumn } from "../../../../@types/table";
import { searchQuery } from "../../../../selectors/seacrhSelector";
import { resetSearch } from "../../../../store/slices/search-slice";
import { AppDispatch } from "../../../../store";
// import { WalletInfo } from "../../../../@types/wallet";
import { useNavigate } from "react-router-dom";
import { RaffleWinnerModel } from "../../../../@types/raffle";
import dayjs from "dayjs";
import { raffleWinners } from "../../../../selectors/raffleSelector";
import { Button, Spin } from "antd";
import { formatCurrency } from "../../../../utils";
import { rolesSelectorList } from "../../../../selectors/roleSelector";
import { currentUserInfomation } from "../../../../selectors/authSelectors";
import { staffSelectorData } from "../../../../selectors/staffSelector";

const RaffleWinnersPage = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const raffleWinnersData: RaffleWinnerModel[] | null =
    useSelector(raffleWinners);
  const [sortDirection, setSortDirection] = useState("descending");

  const isLoading = useSelector(usersSelectorLoadingState);
  const search = useSelector(searchQuery);
  const roles = useSelector(rolesSelectorList);
  const user = useSelector(currentUserInfomation);
  const partners = useSelector(staffSelectorData);

  const theme = useTheme();

  const [activeTab, setActiveTab] = useState<number>(0);
  const [selectedPartner, setSelectedPartner] = useState<string>("All");

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    dispatch(resetSearch());
  }, [dispatch]);

  useEffect(() => {
    // Automatically select the partner if the user is a partner
    if (user && roles) {
      const userRole = roles.find((role) => role.roleID === user.roleID);
      if (userRole?.roleName.includes("Admin")) {
        setSelectedPartner("All"); // Admins can view all or select a partner
      } else if (userRole?.roleName === "ReadOnlyPartner") {
        setSelectedPartner(user.staffID); // Automatically select partner
      }
    }
  }, [user, roles]);

  const filteredPartners = useMemo(() => {
    return partners.filter((partner) => {
      const role = roles.find((r) => r.roleID === partner.roleID);
      return role && role.roleName === "ReadOnlyPartner";
    });
  }, [partners, roles]);

  const columns: CustomColumn<RaffleWinnerModel>[] = useMemo(
    () => [
      { id: "winnerPhoneNumber", label: "User" },
      {
        id: "winnerID",
        label: "Winner ID",
      },
      {
        id: "price",
        label: "Win Price (NGN)",
        format: (value) => formatCurrency(value),
      },
      {
        id: "paymentPaid",
        label: "Status",
        format: (value: boolean) => (
          <Typography
            style={{
              color: value ? "green" : "red", // Green for 'Paid', red for 'Pending'
              fontWeight: "bold",
            }}
          >
            {value ? "Paid" : "Pending"}
          </Typography>
        ),
      },
      {
        id: "drawDate",
        label: "Date",
        format: (value: string) => dayjs(value).format("YYYY-MM-DD"), // assuming the string is in a recognizable date format
      },
    ],
    []
  );

  const filteredWinners = useMemo(() => {
    if (!selectedPartner || selectedPartner == "All") {
      // If 'All' is selected or no partner is selected, return all tickets within the date range
      return raffleWinnersData;
    }
    const partner = filteredPartners.find((p) => p.staffID === selectedPartner);
    const partnerChannel = partner ? partner.channelName : null; // Single channel ID as a string

    if (!partnerChannel) {
      // If no channel is found for the partner, possibly return no tickets or handle appropriately
      return [];
    }

    // Return only tickets that match the partner's channel and are within the date range
    return raffleWinnersData.filter(
      (winner) => winner.channelID === partnerChannel
    );
  }, [raffleWinnersData, selectedPartner, partners]);

  const sortedAndFilteredWallets = useMemo(() => {
    let sortableItems = filteredWinners ? [...filteredWinners] : [];
    if (search) {
      const searchLower = search.toLowerCase(); // Convert search term to lowercase once
      sortableItems = sortableItems.filter(
        (winner) =>
          winner.winnerPhoneNumber.toLowerCase().includes(searchLower) ||
          winner.raffleID.toLowerCase().includes(searchLower) ||
          winner.winnerID.toLowerCase().includes(searchLower) ||
          winner.price.toString().toLowerCase().includes(searchLower) ||
          winner.paymentPaid.toString().toLowerCase().includes(searchLower)
      );
    }

    // Sorting logic
    sortableItems.sort((a, b) => {
      if (a.drawDate < b.drawDate) {
        return sortDirection === "ascending" ? -1 : 1;
      }
      if (a.drawDate > b.drawDate) {
        return sortDirection === "ascending" ? 1 : -1;
      }
      return 0;
    });

    return sortableItems;
  }, [raffleWinnersData, search, sortDirection]);

  // Function to toggle sort direction
  const toggleSortDirection = () => {
    setSortDirection((prevDirection) =>
      prevDirection === "ascending" ? "descending" : "ascending"
    );
  };

  const handleUserClick = (winner: RaffleWinnerModel) => {
    navigate(`/dashboard/raffle-winners/${winner.winnerID}`);
  };

  const paidTransactions = useMemo(() => {
    return (
      sortedAndFilteredWallets?.filter((winner) => winner.paymentPaid) || []
    );
  }, [sortedAndFilteredWallets]);

  const pendingTransactions = useMemo(() => {
    return (
      sortedAndFilteredWallets?.filter((winner) => !winner.paymentPaid) || []
    );
  }, [sortedAndFilteredWallets]);

  const handlePartnerChange = (event: SelectChangeEvent<string>) => {
    setSelectedPartner(event.target.value);
  };

  if (isLoading || !roles || !user) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spin size="large" />
      </Box>
    );
  }
  const getRoleData = (roleID: string) => {
    const role = roles.find((role) => role.roleID === roleID);
    return role;
  };
  const roleName = getRoleData(user?.roleID)?.roleName || "ReadOnlyPartner";

  return (
    <Box padding={theme.spacing(3)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box
            justifyContent="space-between"
            display="flex"
            alignItems="center"
          >
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              sx={{ fontWeight: "bold" }}
            >
              Raffle Winners
            </Typography>
            <Button onClick={toggleSortDirection}>Toggle Date Sort</Button>
          </Box>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent={"flex-end"}
            sx={{ p: 2 }}
          >
            {(roleName === "Super Admin" || roleName === "Admin") && (
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="partner-select-label">
                    Select Partner
                  </InputLabel>
                  <Select
                    labelId="partner-select-label"
                    id="partner-select"
                    value={selectedPartner}
                    onChange={handlePartnerChange}
                  >
                    <MenuItem value="All">
                      <em>All</em>
                    </MenuItem>
                    {filteredPartners.map((partner) => (
                      <MenuItem key={partner.staffID} value={partner.staffID}>
                        {`${partner.firstName} ${partner.lastName}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label="Payment Status Tabs"
          >
            <Tab label="Paid" />
            <Tab label="Pending" />
          </Tabs>
        </Grid>

        {isLoading ? (
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <Typography> Loading...</Typography>
          </Grid>
        ) : (
          <Grid item xs={12}>
            {activeTab === 0 ? (
              <ReusableTable
                data={paidTransactions}
                columns={columns}
                paginationOptions={{
                  rowsPerPage: 20,
                  rowsPerPageOptions: [10, 20, 30],
                  showPagination: true,
                }}
                enableRowClick={true}
                onRowClick={(item) => handleUserClick(item)}
              />
            ) : (
              <ReusableTable
                data={pendingTransactions}
                columns={columns}
                paginationOptions={{
                  rowsPerPage: 20,
                  rowsPerPageOptions: [10, 20, 30],
                  showPagination: true,
                }}
                enableRowClick={true}
                onRowClick={(item) => {
                  if (roleName === "Super Admin" || roleName === "Admin") {
                    handleUserClick(item);
                  }
                }}
              />
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default RaffleWinnersPage;
