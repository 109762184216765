import { RootState } from "../store";

export const raffleLoadingState = (state: RootState) => state.raffle.loading;
export const raffleStateError = (state: RootState) => state.raffle.error;
export const raffleStateMessage = (state: RootState) => state.raffle.message;
export const raffleTicketsList = (state: RootState) =>
  state.raffle.raffleTickets;
export const raffleWinners = (state: RootState) => state.raffle.raffleWinners;
export const raffleTransactions = (state: RootState) =>
  state.raffle.raffleTrasactions;
export const raffleSettingsData = (state: RootState) =>
  state.raffle.raffleSetting;

export const payoutTransaction = (state: RootState) =>
  state.raffle.rafflePayouts;

export const monthlyDrawWinnersResponse = (state: RootState) =>
  state.raffle.manualMonthlyTriggerInfo;
export const dailyDrawWinnersResponse = (state: RootState) =>
  state.raffle.manualDailyTriggerInfo;
