import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

import authReducer from "../store/slices/auth-slice";
import usersReducer from "../store/slices/user-slice";
import messagesReducer from "../store/slices/message-slice";
import staffReducer from "../store/slices/staff-slice";
import walletReducer from "../store/slices/wallet-slice";
import searchReducer from "../store/slices/search-slice";
import rolesReducer from "../store/slices/roles-slice";
import productsSlice from "../store/slices/product-slice";
import boxSlice from "../store/slices/box-slices";
import gameReducer from "../store/slices/game-slice";
import raffleReducer from "../store/slices/raffle-slice";

// Configurations for redux-persist
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"], // Only persist the 'auth' slice
};

const persistedAuthReducer = persistReducer(persistConfig, authReducer);

export const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    users: usersReducer,
    staff: staffReducer,
    messages: messagesReducer,
    wallet: walletReducer,
    search: searchReducer,
    roles: rolesReducer,
    products: productsSlice,
    box: boxSlice,
    game: gameReducer,
    raffle: raffleReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: {
        ignoredActions: ["MY_IGNORED_ACTION", "persist/PERSIST"],
      },
    }),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
