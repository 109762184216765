export const getStatusColor = (status: string) => {
  switch (status) {
    case "pending":
      return "#d9534f"; // Bootstrap's warning color
    case "processing":
      return "#ea8730"; // Bootstrap's info color
    case "delivered":
    case "delivered":
    case "buy-back":
    case "cash-exchange":
      return "green"; // Bootstrap's success color
    case "in-transit":
      return "#12b4e1"; // Bootstrap's primary color
    default:
      return "#6c7d78"; // Bootstrap's secondary color
  }
};

export function formatCurrency(
  amount: number,
  addCurrency = false,
  currencyCode = "NGN"
) {
  // Utilize Intl.NumberFormat to handle the amount directly without rounding it first
  const formatter = new Intl.NumberFormat("en-NG", {
    // Changed locale to "en-NG" for Nigeria
    style: "currency",
    currency: currencyCode,
    // Optional: You can specify minimumFractionDigits to ensure consistent decimal places
    minimumFractionDigits: 0, // Adjust as needed for decimal visibility
  });

  let formattedAmount = formatter.format(amount);

  // Optionally remove the currency symbol if not required
  if (!addCurrency) {
    formattedAmount = formattedAmount.replace(/[^\d.,-]/g, "").trim(); // Adjust regex to retain negative sign
  }

  return formattedAmount;
}
