import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
  raffleSettingsData,
  raffleTicketsList,
  raffleWinners,
} from "../../../../selectors/raffleSelector";
import { currentUserInfomation } from "../../../../selectors/authSelectors";
import { rolesSelectorList } from "../../../../selectors/roleSelector";
import {
  staffSelectorData,
  staffSelectorLoadingState,
} from "../../../../selectors/staffSelector";
import { Spin } from "antd";
import FixInfoCard from "../info-fix-card";
import { formatCurrency } from "../../../../utils";
import { RaffleTicketModel } from "../../../../@types/raffle";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { SelectChangeEvent } from "@mui/material/Select";
import { Column as AntsColumnPlot } from "@ant-design/plots";

dayjs.extend(isSameOrBefore);
dayjs.extend(weekOfYear);

const today = dayjs().startOf("day");
const endOfDay = dayjs().endOf("day");

interface TicketCountPerChannel {
  [channelID: string]: number; // Allows dynamic access using string keys
}

interface ChartDataItem {
  category: string;
  value: number;
}

interface RevenuePerChannel {
  [channelID: string]: number; // Similarly for revenue
}

interface DateRange {
  start: dayjs.Dayjs;
  end: dayjs.Dayjs;
}

const dateRanges: Record<string, DateRange> = {
  today: { start: today, end: endOfDay },
  yesterday: {
    start: today.subtract(1, "day"),
    end: endOfDay.subtract(1, "day"),
  },
  thisWeek: { start: today.startOf("week"), end: today.endOf("week") },
  lastWeek: {
    start: today.subtract(1, "week").startOf("week"),
    end: today.subtract(1, "week").endOf("week"),
  },
  thisMonth: { start: today.startOf("month"), end: today.endOf("month") },
  lastMonth: {
    start: today.subtract(1, "month").startOf("month"),
    end: today.subtract(1, "month").endOf("month"),
  },
  // custom: Implement custom date range logic
};

const PartnerDashboard: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isLoading = useSelector(staffSelectorLoadingState);

  const partners = useSelector(staffSelectorData);
  const roles = useSelector(rolesSelectorList);
  const user = useSelector(currentUserInfomation);
  const raffleTicketsData = useSelector(raffleTicketsList) || [];
  const raffleWinnersData = useSelector(raffleWinners) || [];
  const raffleSettings = useSelector(raffleSettingsData);

  const [selectedPartner, setSelectedPartner] = useState<string>("All");
  const [selectedTimePeriod, setSelectedTimePeriod] = useState("today");
  const [openModal, setOpenModal] = useState(false);

  const [customDateRange, setCustomDateRange] = useState<{
    start: dayjs.Dayjs;
    end: dayjs.Dayjs;
  }>({
    start: today,
    end: endOfDay,
  });

  const [tempDateRange, setTempDateRange] = useState({
    start: today.format("YYYY-MM-DD"),
    end: endOfDay.format("YYYY-MM-DD"),
  });

  useEffect(() => {
    // Automatically select the partner if the user is a partner
    if (user && roles) {
      const userRole = roles.find((role) => role.roleID === user.roleID);
      if (userRole?.roleName.includes("Admin")) {
        setSelectedPartner("All"); // Admins can view all or select a partner
      } else if (userRole?.roleName === "ReadOnlyPartner") {
        setSelectedPartner(user.staffID); // Automatically select partner
      }
    }
  }, [user, roles]);

  const timePeriods = [
    { value: "today", label: "Today" },
    { value: "yesterday", label: "Yesterday" },
    { value: "thisWeek", label: "This Week" },
    { value: "lastWeek", label: "Last Week" },
    { value: "thisMonth", label: "This Month" },
    { value: "lastMonth", label: "Last Month" },
    { value: "custom", label: "Custom" },
  ];
  const handleDateRangeChange = (event: SelectChangeEvent<string>) => {
    const period = event.target.value;
    if (period === "custom") {
      setOpenModal(true);
    } else {
      setSelectedTimePeriod(period);
      setCustomDateRange(dateRanges[period] || dateRanges.today);
    }
  };

  const handleApplyCustomDate = () => {
    setCustomDateRange({
      start: dayjs(tempDateRange.start),
      end: dayjs(tempDateRange.end),
    });
    setSelectedTimePeriod("custom");
    setOpenModal(false);
  };

  const handleCancelCustomDate = () => {
    setCustomDateRange(dateRanges.today); // Reset to today
    setSelectedTimePeriod("today");
    setOpenModal(false);
  };

  const handlePartnerChange = (event: SelectChangeEvent<string>) => {
    setSelectedPartner(event.target.value);
  };

  const filteredPartners = useMemo(() => {
    return partners.filter((partner) => {
      const role = roles.find((r) => r.roleID === partner.roleID);
      return role && role.roleName === "ReadOnlyPartner";
    });
  }, [partners, roles]);

  const filteredTickets = useMemo(() => {
    if (!selectedPartner || selectedPartner == "All") {
      // If 'All' is selected or no partner is selected, return all tickets within the date range
      return raffleTicketsData.filter(
        (ticket) =>
          dayjs(ticket.raffleDate).isSameOrAfter(customDateRange.start) &&
          dayjs(ticket.raffleDate).isSameOrBefore(customDateRange.end)
      );
    }

    const partner = filteredPartners.find((p) => p.staffID === selectedPartner);
    const partnerChannel = partner ? partner.channelName : null; // Single channel ID as a string

    if (!partnerChannel) {
      // If no channel is found for the partner, possibly return no tickets or handle appropriately
      return [];
    }

    // Return only tickets that match the partner's channel and are within the date range
    return raffleTicketsData.filter(
      (ticket) =>
        ticket.channelID === partnerChannel &&
        dayjs(ticket.raffleDate).isSameOrAfter(customDateRange.start) &&
        dayjs(ticket.raffleDate).isSameOrBefore(customDateRange.end)
    );
  }, [raffleTicketsData, selectedPartner, partners, customDateRange]);

  const filteredWinners = useMemo(() => {
    if (!selectedPartner || selectedPartner == "All") {
      // If 'All' is selected or no partner is selected, return all tickets within the date range
      return raffleWinnersData.filter(
        (winner) =>
          dayjs(winner.drawDate).isSameOrAfter(customDateRange.start) &&
          dayjs(winner.drawDate).isSameOrBefore(customDateRange.end)
      );
    }
    const partner = filteredPartners.find((p) => p.staffID === selectedPartner);
    const partnerChannel = partner ? partner.channelName : null; // Single channel ID as a string

    if (!partnerChannel) {
      // If no channel is found for the partner, possibly return no tickets or handle appropriately
      return [];
    }

    // Return only tickets that match the partner's channel and are within the date range
    return raffleWinnersData.filter(
      (winner) =>
        winner.channelID === partnerChannel &&
        dayjs(winner.drawDate).isSameOrAfter(customDateRange.start) &&
        dayjs(winner.drawDate).isSameOrBefore(customDateRange.end)
    );
  }, [raffleWinnersData, selectedPartner, partners, customDateRange]);

  const allTimeUniqueUsers = useMemo(() => {
    const users = new Set();
    raffleTicketsData.forEach((ticket) => {
      if (ticket.userPhoneNumber) {
        users.add(ticket.userPhoneNumber);
      }
    });
    return users;
  }, [raffleTicketsData]);

  const ticketCounts = useMemo(() => {
    return raffleTicketsData.reduce(
      (acc, ticket) => {
        const userKey = ticket.userPhoneNumber; // Use phone number as the key
        acc[userKey] = (acc[userKey] || 0) + 1;
        return acc;
      },
      {} as { [userPhoneNumber: string]: number }
    );
  }, [raffleTicketsData]);

  const channelPrices = useMemo(() => {
    const prices: { [key: string]: number } = {};
    if (raffleSettings && raffleSettings.raffleChannels) {
      raffleSettings.raffleChannels.forEach((channel) => {
        prices[channel.channelID] = channel.entryPrice;
      });
    }
    return prices;
  }, [raffleSettings]);

  const totalRevenueForPeriod = useMemo(() => {
    return filteredTickets.reduce((acc, ticket) => {
      const ticketPrice = channelPrices[ticket.channelID] || 0; // Assuming each ticket has a 'channelID' and that 'channelPrices' maps these IDs to their prices.
      return acc + ticketPrice;
    }, 0);
  }, [filteredTickets, channelPrices]);

  if (
    isLoading ||
    !user ||
    !filteredTickets ||
    !filteredWinners ||
    !raffleSettings ||
    !partners
  ) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spin size="large" />
      </Box>
    );
  }
  const getRoleData = (roleID: string) => {
    const role = roles.find((role) => role.roleID === roleID);
    return role;
  };

  const categorizeUserPurchases = (ticketCounts: {
    [userPhoneNumber: string]: number;
  }) => {
    const categories = {
      "1": 0,
      "2-3": 0,
      "4-5": 0,
      "6-10": 0,
      "11-20": 0,
      "21-50": 0,
      "51+": 0,
    };

    Object.values(ticketCounts).forEach((count) => {
      if (count === 1) categories["1"] += 1;
      else if (count >= 2 && count <= 3) categories["2-3"] += 1;
      else if (count >= 4 && count <= 5) categories["4-5"] += 1;
      else if (count >= 6 && count <= 10) categories["6-10"] += 1;
      else if (count >= 11 && count <= 20) categories["11-20"] += 1;
      else if (count >= 21 && count <= 50) categories["21-50"] += 1;
      else categories["51+"] += 1;
    });

    return categories;
  };

  const purchaseCategories = categorizeUserPurchases(ticketCounts);

  const chartFreqData: ChartDataItem[] = Object.entries(purchaseCategories).map(
    ([category, value]) => ({
      category,
      value,
    })
  );

  const calculateRevenuePerChannel = (
    tickets: RaffleTicketModel[],
    prices: { [key: string]: number }
  ): RevenuePerChannel => {
    return tickets.reduce<RevenuePerChannel>((acc, ticket) => {
      const price = prices[ticket.channelID] || 0;
      acc[ticket.channelID] = (acc[ticket.channelID] || 0) + price;
      return acc;
    }, {} as RevenuePerChannel);
  };

  // Assuming channelPrices is defined
  const revenuePerChannel = calculateRevenuePerChannel(
    filteredTickets,
    channelPrices
  );

  // Assuming raffleTicketsData is an array of tickets where each ticket has a channelID.
  const ticketCountPerChannel = filteredTickets.reduce<TicketCountPerChannel>(
    (acc, ticket) => {
      acc[ticket.channelID] = (acc[ticket.channelID] || 0) + 1;
      return acc;
    },
    {}
  );

  // Calculate total amount expected to be paid out to winners (unpaid prizes)
  const totalAmountExpectedToPayOut = filteredWinners.reduce((acc, winner) => {
    if (!winner.paymentPaid) {
      return acc + winner.price;
    }
    return acc;
  }, 0);

  // Calculate total amount actually paid out (paid prizes)
  const totalAmountPaidOut = filteredWinners.reduce((acc, winner) => {
    if (winner.paymentPaid) {
      return acc + winner.price;
    }
    return acc;
  }, 0);

  // Calculate Profit
  const profit = totalRevenueForPeriod - totalAmountPaidOut;
  const profitChangeType = profit >= 0 ? "increase" : "decrease";

  const getLabelForSelectedPeriod = (selectedPeriod: string) => {
    const period = timePeriods.find(
      (period) => period.value === selectedPeriod
    );
    return period ? period.label : "Unknown period"; // Return 'Unknown period' if no match is found
  };

  const label = getLabelForSelectedPeriod(selectedTimePeriod);
  const remainingPayoutChange =
    totalAmountExpectedToPayOut > 0 ? "decrease" : "increase";
  const totalPaidChange =
    totalAmountExpectedToPayOut === 0 ? "increase" : "decrease";

  const roleName = getRoleData(user?.roleID)?.roleName || "ReadOnlyPartner";

  return (
    <Box sx={{ p: isMobile ? 1 : 3 }}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
        mb={3}
      >
        <Grid item xs={12} sm={6}>
          <Typography
            variant="h4"
            gutterBottom
            component="div"
            sx={{ fontWeight: "bold" }}
          >
            Raffles Dashboard
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent={
          roleName === "Super Admin" || roleName === "Admin"
            ? "space-between"
            : "flex-end"
        }
        sx={{ p: 2 }}
      >
        {(roleName === "Super Admin" || roleName === "Admin") && (
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="partner-select-label">Select Partner</InputLabel>
              <Select
                labelId="partner-select-label"
                id="partner-select"
                value={selectedPartner}
                onChange={handlePartnerChange}
              >
                <MenuItem value="All">
                  <em>All</em>
                </MenuItem>
                {filteredPartners.map((partner) => (
                  <MenuItem key={partner.staffID} value={partner.staffID}>
                    {`${partner.firstName} ${partner.lastName}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel id="time-period-label">Time Period</InputLabel>
            <Select
              labelId="time-period-label"
              id="time-period-select"
              value={selectedTimePeriod}
              onChange={handleDateRangeChange}
            >
              {timePeriods.map((period) => (
                <MenuItem key={period.value} value={period.value}>
                  {period.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {selectedTimePeriod === "custom" && (
        <Box
          sx={{
            mt: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 2,
          }}
        >
          <Typography variant="body2">
            Custom Range: {customDateRange.start.format("YYYY-MM-DD")} -{" "}
            {customDateRange.end.format("YYYY-MM-DD")}
          </Typography>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={handleCancelCustomDate}
          >
            Clear
          </Button>
        </Box>
      )}
      <Grid container spacing={2} mb={5}>
        <Grid container spacing={2} mt={4} alignItems="stretch">
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            container
            direction="column"
            spacing={2}
          >
            <Grid item>
              <FixInfoCard
                info={`Total Revenue ${label}`}
                value={formatCurrency(totalRevenueForPeriod, true)}
              />
            </Grid>
            <Grid item>
              <FixInfoCard
                info={`Profit ${label}`}
                profitChange={profitChangeType}
                value={formatCurrency(profit, true)}
              />
            </Grid>
            <Grid item>
              <FixInfoCard
                info={`Raffle Tickets  ${label}`}
                value={filteredTickets.length.toString()}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <Typography variant="h6">User Participation Frequency</Typography>
            <AntsColumnPlot
              data={chartFreqData}
              xField="category"
              yField="value"
              colorField="category"
              isGroup
              columnStyle={({ category }: ChartDataItem) => ({
                fill: category.includes("+") ? "#fa541c" : "#1890ff",
              })}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} md={4} mb={2}>
          <FixInfoCard
            info={`Remaining Payout ${label}`}
            value={formatCurrency(totalAmountExpectedToPayOut, true)}
            profitChange={remainingPayoutChange}
          />
        </Grid>
        <Grid item xs={12} md={4} mb={2}>
          <FixInfoCard
            info={`Total Paid ${label}`}
            value={formatCurrency(totalAmountPaidOut, true)}
            profitChange={totalPaidChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FixInfoCard
            info={`Raffle Winners ${label}`}
            value={filteredWinners.length.toString()}
          />{" "}
        </Grid>
        <Grid item xs={12} md={4} mb={2}>
          <FixInfoCard
            info={`Total Unique Users`}
            value={allTimeUniqueUsers.size.toString()}
          />
        </Grid>

        {Object.keys(revenuePerChannel).length > 0 ? (
          Object.keys(revenuePerChannel).map((channelID) => (
            <Grid key={channelID} item xs={12} sm={6} md={4}>
              <FixInfoCard
                info={`Channel ${channelID} Revenue`}
                value={formatCurrency(revenuePerChannel[channelID], true)}
              />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography>No revenue data available for this period.</Typography>
          </Grid>
        )}
        {Object.keys(ticketCountPerChannel).length > 0 ? (
          Object.keys(ticketCountPerChannel).map((channelID) => (
            <Grid key={channelID} item xs={12} sm={6} md={4}>
              <FixInfoCard
                info={`Channel ${channelID} Tickets ${label}`}
                value={ticketCountPerChannel[channelID].toString()}
              />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography>No ticket data available for this period.</Typography>
          </Grid>
        )}
      </Grid>

      <Dialog open={openModal} onClose={handleCancelCustomDate}>
        <DialogTitle>Select Custom Date Range</DialogTitle>
        <DialogContent>
          <TextField
            label="Start Date"
            type="date"
            value={tempDateRange.start}
            onChange={(e) =>
              setTempDateRange((prev) => ({ ...prev, start: e.target.value }))
            }
            fullWidth
            sx={{ mb: 2 }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="End Date"
            type="date"
            value={tempDateRange.end}
            onChange={(e) =>
              setTempDateRange((prev) => ({ ...prev, end: e.target.value }))
            }
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelCustomDate} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleApplyCustomDate} color="primary">
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PartnerDashboard;
