import { Box, Paper, Typography } from "@mui/material";
import HeaderActions from "../../../../components/header-action";
import React from "react";

interface FixInfoCardProps {
  value: string;
  info: string;
  profitChange?: "increase" | "decrease";
}

const FixInfoCard: React.FC<FixInfoCardProps> = ({
  value,
  info,
  profitChange,
}) => {
  const backgroundColor =
    profitChange === "increase"
      ? "#90ee90"
      : profitChange === "decrease"
        ? "#ffcccb"
        : "#ADD8E6";

  const textColor =
    profitChange === "increase"
      ? "darkgreen" // Dark green text on light green background
      : profitChange === "decrease"
        ? "darkred" // Dark red text on light red background
        : "#4169E1"; // Default blue text on default background

  return (
    <Paper
      elevation={3}
      sx={{ p: 2, display: "flex", flexDirection: "column" }}
    >
      <HeaderActions
        title={info}
        triggerTitle="View"
        titleFontSize="16px"
        titleFontWeight="800"
        iconColor="grey"
        profitChange={profitChange}
        showMore={false}
        sxIcon={{ transform: "scale(1.2)" }}
      />

      <Box
        p={3}
        mb={1}
        sx={{
          backgroundColor: backgroundColor,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{ fontWeight: "bolder", fontSize: "25px", color: textColor }}
        >
          {value}
        </Typography>
      </Box>
    </Paper>
  );
};

export default FixInfoCard;
