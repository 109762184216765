import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import ReusableTable from "../../../../components/reusable-table";
import { CustomColumn } from "../../../../@types/table";

import {
  rolesLoading,
  rolesSelectorList,
} from "../../../../selectors/roleSelector";

import { RoleModel } from "../../../../@types/roles-model";
import { AppDispatch } from "../../../../store";
import { useNavigate } from "react-router-dom";
import { deleteRole, getRoles } from "../../../../store/thunks/roles-thunks";
import logging from "../../../../logging";

const AccessRoleList = () => {
  const isLoading = useSelector(rolesLoading);
  const roles = useSelector(rolesSelectorList);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [currentRole, setCurrentRole] = useState<RoleModel | null>(null);
  const [dialogType, setDialogType] = useState<"edit" | "delete">("delete");

  const columns: CustomColumn<RoleModel>[] = [
    { id: "roleID", label: "Role ID" },
    { id: "roleName", label: "Role Name" },
    {
      id: "permissions",
      label: "Permissions",
      accessor: (item: RoleModel) =>
        Object.keys(item.permissions)
          .filter((key) => item.permissions[key])
          .join(", "),
    },
  ];

  const handleEdit = (role: RoleModel) => {
    setDialogType("edit");
    setCurrentRole(role);
    setOpen(true);
  };

  const handleDelete = (role: RoleModel) => {
    setDialogType("delete");
    setCurrentRole(role);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentRole(null);
  };

  const handleConfirm = () => {
    if (dialogType === "delete") {
      handleDisptachDelete();
    } else {
      handleUserClick();
    }
  };

  const handleUserClick = () => {
    if (!currentRole) return;
    navigate(`/dashboard/roles-list/${currentRole.roleID}`);
    handleClose();
  };
  const handleDisptachDelete = async () => {
    if (!currentRole) return;
    try {
      const action = deleteRole(currentRole.roleID);
      const resultAction = await dispatch(action);
      // Check if it's an error message/object based on some criterion
      if (
        typeof resultAction.payload === "string" ||
        "error" in resultAction.payload
      ) {
        logging.error(resultAction.payload);
      } else if (deleteRole.fulfilled.match(resultAction)) {
        dispatch(getRoles());
        handleClose();
        logging.info("Role deleted successful!");
      } else {
        // Additional error handling if needed
        logging.error(resultAction.error);
      }
    } catch (error) {
      logging.error("Dispatch failed:", (error as Error).message);
    }
  };

  return (
    <Box padding={theme.spacing(3)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            gutterBottom
            component="div"
            sx={{ fontWeight: "bold" }}
          >
            Access Role List
          </Typography>
        </Grid>
        {isLoading ? (
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <Typography> Loading...</Typography>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <ReusableTable
              data={roles}
              columns={columns}
              paginationOptions={{
                rowsPerPage: 20,
                rowsPerPageOptions: [10, 20, 30],
                showPagination: true,
              }}
              enableRowClick={false}
              onEdit={handleEdit}
              onDelete={handleDelete}
              showDeleteIcon={true}
              showEditIcon={true}
            />
          </Grid>
        )}
      </Grid>
      {/* Dialog Component */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {dialogType === "delete" ? "Confirm Delete" : "Confirm Edit"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialogType === "delete"
              ? "Are you sure you want to delete this role?"
              : "Are you sure you want to edit this role?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AccessRoleList;
