import { AxiosResponse } from "axios";
import ApiService from "./api-service";
import { getUserIdFromToken } from "../utils/jwt-utils";
import { RoleModel } from "../@types/roles-model";

class RoleService extends ApiService {
  async addNewRole(data: Partial<RoleModel>): Promise<any> {
    try {
      const response: AxiosResponse = await this.axiosInstance.post(
        "/roles/create",
        data,
        {
          headers: { "Content-Type": this.getContentType(data) },
        }
      );
      await this.invalidateCacheByKey("get/roles/getAll");
      return response.data;
    } catch (error) {
      console.error("Error creating new role:", error);
      throw error;
    }
  }
  async fetchRoles(): Promise<RoleModel[]> {
    try {
      const response: AxiosResponse =
        await this.axiosInstance.get("/roles/getAll");
      return response.data;
    } catch (error) {
      console.error("Failed to fetch users:", error);
      throw error;
    }
  }

  async fetchRolesByID(): Promise<RoleModel> {
    try {
      const roleID = getUserIdFromToken();
      if (!roleID) {
        throw new Error("No staffID found in the token");
      }
      const response: AxiosResponse = await this.axiosInstance.get(
        `/roles/getById/${roleID}`
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch users:", error);
      throw error;
    }
  }

  async updateRole(payload: Partial<RoleModel>): Promise<any> {
    try {
      const response: AxiosResponse = await this.axiosInstance.put(
        `/roles/update/${payload.roleID}`,
        payload,
        {
          headers: { "Content-Type": this.getContentType(payload) },
        }
      );
      await this.invalidateCacheByKey(`get/roles/getById/${payload.roleID}`);
      await this.invalidateCacheByKey("get/roles/getAll");
      return response.data;
    } catch (error) {
      console.error("Failed to update roles data:", error);
      throw error;
    }
  }

  async deleteRoleById(roleID: string): Promise<any> {
    try {
      const response: AxiosResponse = await this.axiosInstance.delete(
        `/roles/delete/${roleID}`,
        {
          headers: { "Content-Type": this.getContentType(roleID) },
        }
      );
      await this.invalidateCacheByKey(`get/roles/getById/${roleID}`);
      await this.invalidateCacheByKey("get/roles/getAll");
      return response.data;
    } catch (error) {
      console.error("Failed to delete role data:", error);
      throw error;
    }
  }
}

const roleService = new RoleService();
export default roleService;
