import React, { useRef, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  TextField,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { Formik, Form, FormikProps } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { usersSelectorLoadingState } from "../../../../selectors/userSelector";
import { Spin } from "antd";
import { AppDispatch } from "../../../../store";
import logging from "../../../../logging";
import {
  requestTokenToEmail,
  StaffModel,
} from "../../../../@types/staff-model";
import {
  getStaffs,
  sendPasswordResetToken,
  updateStaff,
} from "../../../../store/thunks/staff-thunks";
import CustomDropdown from "../../../../components/custom-dropdown";
import { rolesSelectorList } from "../../../../selectors/roleSelector";
import { currentUserInfomation } from "../../../../selectors/authSelectors";

import { ResetPasswordFormValues } from "../../../../@types/user-model";
import ResetPasswordModal from "./reset-passord-modal";
import { staffSelectorLoadingState } from "../../../../selectors/staffSelector";

const Profile = () => {
  const isLoading = useSelector(staffSelectorLoadingState);

  const roles = useSelector(rolesSelectorList);
  const user = useSelector(currentUserInfomation);

  const [editMode, setEditMode] = useState(false);
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
  const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = useState(false);

  const handleResetPasswordClick = () => {
    setResetPasswordModalOpen(true);
  };

  const handleCloseResetPasswordModal = () => {
    setResetPasswordModalOpen(false);
  };

  const handleResetPasswordSubmit = async (values: ResetPasswordFormValues) => {
    console.log("Password Reset Values:", values);
    // Here you would implement the logic to actually reset the password
    handleCloseResetPasswordModal();
  };

  // Handles opening the reset password dialog
  const handleOpenResetPasswordDialog = () => {
    setResetPasswordDialogOpen(true);
  };

  // Handles closing the reset password dialog
  const handleCloseResetPasswordDialog = () => {
    setResetPasswordDialogOpen(false);
  };

  const dispatch: AppDispatch = useDispatch();

  const formikRef = useRef<FormikProps<any>>(null);

  const getRoleData = (roleID: string) => {
    const role = roles.find((role) => role.roleID === roleID);
    return role;
  };

  const enterEditMode = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    console.log("Entering edit mode");
    setEditMode(true);
  };

  const exitEditMode = (event?: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault(); // Optional chaining in case event is undefined
    console.log("Exiting edit mode");
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
    setEditMode(false);
  };

  const handleSubmit = async (values: StaffModel, actions: any) => {
    try {
      const resultAction = await dispatch(updateStaff(values));
      if (updateStaff.fulfilled.match(resultAction)) {
        await dispatch(getStaffs());
        logging.info("user data updated successful!");
      } else if (
        resultAction.payload &&
        typeof resultAction.payload === "string"
      ) {
        logging.error(resultAction.payload);
      } else {
        logging.error(resultAction.error);
      }
    } catch (error) {
      logging.error("Dispatch failed:", (error as Error).message);
    } finally {
      actions.setSubmitting(false);
      exitEditMode();
    }
  };

  const handleSendTokenToEmail = async () => {
    if (!user) return;

    const payload: requestTokenToEmail = {
      email: user.email,
    };
    try {
      const resultAction = await dispatch(sendPasswordResetToken(payload));
      if (sendPasswordResetToken.fulfilled.match(resultAction)) {
        logging.info("Token sent successful!");
        setResetPasswordDialogOpen(false);
        handleResetPasswordClick();
      } else if (
        resultAction.payload &&
        typeof resultAction.payload === "string"
      ) {
        logging.error(resultAction.payload);
      } else {
        logging.error(resultAction.error);
      }
    } catch (error) {
      logging.error("Dispatch failed:", (error as Error).message);
    }
  };

  if (isLoading || !user) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spin size="large" />
      </Box>
    );
  }

  const initialValues: StaffModel = {
    staffID: user.staffID, // Ensure this is always defined and is never `undefined`
    roleID: user.roleID ?? "", // Provide default values for potentially undefined properties
    email: user.email ?? "",
    firstName: user.firstName ?? "",
    lastName: user.lastName ?? "",
    address: user.address ?? "",
    postalCode: user.postalCode ?? "",
    phoneNumber: user.phoneNumber ?? "",
    pictureUrl: user.pictureUrl ?? "",
    password: user.password ?? "",
    emailVerified: user.emailVerified ?? false,
    channelName: user.channelName ?? "",
    createdAt: user.createdAt ?? "false",
  };

  const roleName = getRoleData(user?.roleID)?.roleName === "Super Admin";
  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      // validationSchema={UserSchema}
      onSubmit={handleSubmit}
      validateOnBlur={true}
      validateOnChange={true}
      enableReinitialize={true}
    >
      {({ errors, touched, values, handleChange }) => (
        <Form>
          {/* Confirmation Dialog */}
          <Dialog
            open={resetPasswordDialogOpen}
            onClose={handleCloseResetPasswordDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Confirm Password Reset"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to reset your password? A token will be
                sent to your registered email address.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseResetPasswordDialog} color="primary">
                Cancel
              </Button>
              <Button
                onClick={handleSendTokenToEmail}
                color="primary"
                autoFocus
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <ResetPasswordModal
            email={user.email}
            open={resetPasswordModalOpen}
            handleClose={handleCloseResetPasswordModal}
            handleSubmit={handleResetPasswordSubmit}
          />
          <Box>
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant="h4"
                  gutterBottom
                  component="div"
                  sx={{ fontWeight: "bold" }}
                >
                  Profile
                </Typography>
              </Box>
            </Grid>
            <Box display="flex" justifyContent="space-between" mb={3}>
              <Button
                type="button"
                variant="contained"
                sx={{
                  backgroundColor: "red",
                  "&:hover": { backgroundColor: "darkred" }, // Darkens the button on hover
                  "&:active": { backgroundColor: "darkred" }, // Optional: Change color on click
                  "&:focus": { boxShadow: `0 0 0 2px #ff0000` }, // Focus ring color, for accessibility
                }}
                onClick={handleOpenResetPasswordDialog}
              >
                Reset Password
              </Button>

              {editMode ? (
                <>
                  <Button
                    sx={{ marginRight: 2 }}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save Changes
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    onClick={exitEditMode}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <Button
                  type="button"
                  variant="contained"
                  onClick={enterEditMode}
                >
                  Edit
                </Button>
              )}
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Paper elevation={3} sx={{ p: 2 }}>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    sx={{ fontWeight: "bold", mb: 2 }}
                  >
                    Profile Information
                  </Typography>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    margin="normal"
                    inputProps={{ readOnly: true }}
                    value={values.email}
                  />
                  <TextField
                    fullWidth
                    label="Phone Number"
                    name="phoneNumber"
                    margin="normal"
                    inputProps={{ readOnly: editMode ? false : true }}
                    value={values.phoneNumber}
                    onChange={handleChange}
                    error={touched.email && !!errors.email}
                    helperText={
                      touched.email && typeof errors.email === "string"
                        ? errors.email
                        : ""
                    }
                  />
                  <TextField
                    fullWidth
                    label="First Name"
                    name="firstName"
                    margin="normal"
                    inputProps={{ readOnly: editMode ? false : true }}
                    value={values.firstName}
                    onChange={handleChange}
                    error={touched.firstName && !!errors.firstName}
                    helperText={
                      touched.firstName && typeof errors.firstName === "string"
                        ? errors.firstName
                        : ""
                    }
                  />
                  <TextField
                    fullWidth
                    label="Last Name"
                    name="lastName"
                    margin="normal"
                    inputProps={{ readOnly: editMode ? false : true }}
                    value={values.lastName}
                    onChange={handleChange}
                    error={touched.lastName && !!errors.lastName}
                    helperText={
                      touched.lastName && typeof errors.lastName === "string"
                        ? errors.lastName
                        : ""
                    }
                  />
                  {roleName && (
                    <>
                      {" "}
                      {editMode ? (
                        <CustomDropdown
                          label="Role"
                          roles={roles}
                          name="roleID"
                          defaultValue={values.roleID}
                          required
                        />
                      ) : (
                        <TextField
                          fullWidth
                          label="Role"
                          name="roleID"
                          margin="normal"
                          inputProps={{ readOnly: true }}
                          value={getRoleData(values.roleID)?.roleName}
                        />
                      )}
                    </>
                  )}
                </Paper>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Paper elevation={3} sx={{ p: 2 }}>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    sx={{ fontWeight: "bold", mb: 2 }}
                  >
                    Contact Information
                  </Typography>

                  <TextField
                    fullWidth
                    label="Address"
                    name="address"
                    margin="normal"
                    inputProps={{ readOnly: editMode ? false : true }}
                    value={values.address}
                    onChange={handleChange}
                    error={touched.address && !!errors.address}
                    helperText={
                      touched.address && typeof errors.address === "string"
                        ? errors.address
                        : ""
                    }
                  />
                  <TextField
                    fullWidth
                    label="Postal Code"
                    name="postalCode"
                    margin="normal"
                    inputProps={{ readOnly: editMode ? false : true }}
                    value={values.postalCode}
                    onChange={handleChange}
                    error={touched.postalCode && !!errors.postalCode}
                    helperText={
                      touched.postalCode &&
                      typeof errors.postalCode === "string"
                        ? errors.postalCode
                        : ""
                    }
                  />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
export default Profile;
