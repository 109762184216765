// src/slices/usersSlice.ts
import { createSlice } from "@reduxjs/toolkit";
import { RoleState, RoleModel } from "../../@types/roles-model";
import {
  addNewRoles,
  deleteRole,
  getRoles,
  updateRole,
} from "../thunks/roles-thunks";

const initialState: RoleState = {
  list: [] as RoleModel[],
  status: "idle" as "idle" | "loading" | "succeeded" | "failed",
  error: null as string | null,
  loading: false,
  message: null as string | null,
};

const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRoles.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.message = null;
      })
      .addCase(getRoles.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.list = action.payload;
        state.loading = false;
        state.message = null;
      })
      .addCase(getRoles.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
        state.loading = false;
        state.message = null;
      })
      .addCase(addNewRoles.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.message = null;
      })
      .addCase(addNewRoles.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.list = action.payload;
        state.loading = false;
        state.message = "Role successfully created";
      })
      .addCase(addNewRoles.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
        state.loading = false;
        state.message = null;
      })
      .addCase(deleteRole.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.message = null;
      })
      .addCase(deleteRole.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.list = action.payload;
        state.loading = false;
        state.message = "Role deleted successful";
      })
      .addCase(deleteRole.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
        state.loading = false;
        state.message = null;
      })
      .addCase(updateRole.pending, (state) => {
        state.status = "loading";
        state.loading = true;
        state.message = null;
      })
      .addCase(updateRole.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.list = action.payload;
        state.loading = false;
        state.message = "Role updated successful";
      })
      .addCase(updateRole.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
        state.loading = false;
        state.message = null;
      });
  },
});

export default rolesSlice.reducer;
