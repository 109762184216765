import { useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, FormikProps, useFormikContext } from "formik";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import { Spin } from "antd";

import CheckIcon from "@mui/icons-material/Check";
import * as Yup from "yup";
import {
  RaffleWinnerModel,
  RaffleWinnerPaymentRequest,
} from "../../../../../@types/raffle";
import {
  BankDetailModel,
  BankVerifyRequest,
} from "../../../../../@types/wallet";
import { currentUserInfomation } from "../../../../../selectors/authSelectors";
import { rolesSelectorList } from "../../../../../selectors/roleSelector";
import {
  allBanksDetails,
  isBankAccountVerified,
} from "../../../../../selectors/walletSelector";
import { AppDispatch } from "../../../../../store";
import logging from "../../../../../logging";
import { verifyBankAccount } from "../../../../../store/thunks/wallet-thunk";
import {
  getAllRaffleWinnerPayouts,
  getAllRaffleWinners,
  payWinnerMoney,
} from "../../../../../store/thunks/raffle-thunk";

const bankValidationSchema = Yup.object({
  bankName: Yup.string().required("Bank name is required"),
  accountNumber: Yup.string().required("Account number is required"),
});

interface Props {
  winner: RaffleWinnerModel;
}

const RafflePayment: React.FC<Props> = ({ winner }) => {
  const formikBankRef = useRef<FormikProps<any>>(null);

  const roles = useSelector(rolesSelectorList);
  const user = useSelector(currentUserInfomation);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const allBanksData = useSelector(allBanksDetails);

  const banksOptions = Array.isArray(allBanksData) ? allBanksData : [];
  const [selectedBank, setSelectedBank] = useState<BankDetailModel | null>(
    null
  );
  const [bankAccountName, setBankAccountName] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);

  const getRoleData = (roleID: string) => {
    const role = roles.find((role) => role.roleID === roleID);
    return role;
  };
  useEffect(() => {
    // Ensure that both user and roles are fully loaded
    if (user && roles.length > 0) {
      const roleData = getRoleData(user.roleID);

      // Redirect if the user is not a Super Admin or Admin
      if (
        roleData &&
        roleData.roleName !== "Super Admin" &&
        roleData.roleName !== "Admin"
      ) {
        navigate("/dashboard");
      }
    }
  }, [user, roles, navigate]);

  const handleSubmit = async (values: any, actions: any) => {
    setIsVerifying(true);

    const payload: RaffleWinnerPaymentRequest = {
      accountNumber: values.accountNumber,
      bankCode: values.bankCode,
      bankAccountName: bankAccountName,
      winner: winner,
    };

    try {
      const resultAction = await dispatch(payWinnerMoney(payload));
      if (payWinnerMoney.fulfilled.match(resultAction)) {
        dispatch(getAllRaffleWinners());
        dispatch(getAllRaffleWinnerPayouts());
        logging.info("winner payment made successful!");
      } else if (
        resultAction.payload &&
        typeof resultAction.payload === "string"
      ) {
        logging.error(resultAction.payload);
      } else {
        logging.error(resultAction.error);
      }
    } catch (error) {
      logging.error("Dispatch failed:", (error as Error).message);
    } finally {
      setIsVerifying(false);
      actions.setSubmitting(false);
    }
    // Implement bank account verification logic here
    console.log("submit details:", values);
    // Dispatch an action or call a function to verify bank details
  };

  const handleVerifyBankDetails = async () => {
    if (!formikBankRef.current) return;

    setIsVerifying(true);
    const verifyValue = {
      accountNumber: formikBankRef.current.values.accountNumber,
      bankCode: formikBankRef.current.values.bankCode,
    };
    try {
      const resultAction = await dispatch(verifyBankAccount(verifyValue));
      if (verifyBankAccount.fulfilled.match(resultAction)) {
        setBankAccountName(resultAction.payload.account_name);
        logging.info("winner data updated successful!");
      } else if (
        resultAction.payload &&
        typeof resultAction.payload === "string"
      ) {
        logging.error(resultAction.payload);
      } else {
        logging.error(resultAction.error);
      }
    } catch (error) {
      logging.error("Dispatch failed:", (error as Error).message);
    } finally {
      setIsVerifying(false);
    }
  };

  if (!winner || !user) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spin size="large" />
      </Box>
    );
  }

  const initialBankValues: BankVerifyRequest = {
    accountNumber: "",
    bankCode: "",
  };

  return (
    <Box>
      <Formik
        innerRef={formikBankRef}
        validationSchema={bankValidationSchema}
        initialValues={initialBankValues}
        onSubmit={handleSubmit}
        validateOnBlur={true}
        validateOnChange={true}
        enableReinitialize={true}
      >
        {({ errors, touched, values, handleChange, setFieldValue }) => (
          <Form>
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Typography
                  variant="h4"
                  component="div"
                  sx={{ fontWeight: "bold" }}
                >
                  Pay Winner
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} mb={6}>
              <Paper elevation={3} sx={{ p: 2 }}>
                <Typography
                  variant="subtitle1"
                  color="primary"
                  sx={{ fontWeight: "bold", mb: 2 }}
                >
                  Bank Infomation
                </Typography>
                {!winner.paymentPaid && (
                  <Grid item xs={12} mb={2}>
                    <Autocomplete
                      id="bankName"
                      options={banksOptions}
                      getOptionLabel={(option) => option.bankName}
                      isOptionEqualToValue={(option, value) =>
                        option.bankCode === value.bankCode
                      }
                      onChange={(event, value) => {
                        setSelectedBank({
                          bankName: value ? value.bankName : "",
                          bankCode: value ? value.bankCode : "",
                          bankID: value ? value.bankID : null,
                        });
                        setFieldValue("bankName", value ? value.bankName : "");
                        setFieldValue("bankCode", value ? value.bankCode : "");
                      }}
                      value={selectedBank}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Bank Name"
                          variant="outlined"
                          fullWidth
                          error={touched.bankName && Boolean(errors.bankName)}
                          helperText={
                            touched.bankName &&
                            typeof errors.bankName === "string"
                              ? errors.bankName
                              : ""
                          }
                        />
                      )}
                    />
                  </Grid>
                )}

                <Grid item xs={12} mb={2}>
                  <TextField
                    fullWidth
                    id="accountNumber"
                    name="accountNumber"
                    label="Account Number"
                    value={values.accountNumber}
                    onChange={(e) => {
                      setFieldValue("accountNumber", e.target.value);
                    }}
                    error={
                      touched.accountNumber && Boolean(errors.accountNumber)
                    }
                    helperText={
                      touched.accountNumber &&
                      typeof errors.accountNumber === "string"
                        ? errors.accountNumber
                        : ""
                    }
                  />
                </Grid>
                {bankAccountName && (
                  <Grid item xs={12} mb={2}>
                    <TextField
                      fullWidth
                      id="bankAccountName"
                      name="bankAccountName"
                      label="Account Name"
                      InputProps={{
                        readOnly: true,
                      }}
                      value={bankAccountName}
                    />
                  </Grid>
                )}
                {bankAccountName == "" && (
                  <Grid
                    item
                    xs={12}
                    justifyContent="flex-end"
                    display="flex"
                    alignItems="center"
                  >
                    <Button
                      color={isVerifying ? "success" : "primary"}
                      variant={"contained"}
                      type="button"
                      size="large"
                      disabled={isVerifying}
                      onClick={handleVerifyBankDetails}
                      startIcon={
                        isVerifying ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : null
                      }
                    >
                      Verify
                    </Button>
                  </Grid>
                )}

                {bankAccountName && (
                  <Grid
                    item
                    xs={12}
                    justifyContent="flex-end"
                    display="flex"
                    alignItems="center"
                  >
                    <Button
                      sx={{ background: isVerifying ? "error" : "green" }}
                      variant={"contained"}
                      type="submit"
                      size="large"
                      disabled={isVerifying}
                      startIcon={
                        isVerifying ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : null
                      }
                    >
                      Initiate Payment
                    </Button>
                  </Grid>
                )}
              </Paper>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default RafflePayment;
