import React, { ChangeEvent, useEffect, useState } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Search } from "@mui/icons-material";

import {
  theme as antTheme,
  Layout,
  Menu as AntMenu,
  MenuProps,
  Spin,
} from "antd";

import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  Avatar,
  Badge,
  Box,
  Drawer,
  InputAdornment,
  Button as MUIButton,
  MenuItem,
  Menu as MuiMenu,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { currentUserInfomation } from "../../selectors/authSelectors";
import { searchQuery } from "../../selectors/seacrhSelector";
import { AppDispatch } from "../../store";
import { setSearch } from "../../store/slices/search-slice";
import { rolesLoading, rolesSelectorList } from "../../selectors/roleSelector";
import { logoutStaff } from "../../store/thunks/auth-thunk";
import logging from "../../logging";
import { filterMenuItems, menuConfig } from "./menu-config";
import { RoleModel } from "../../@types/roles-model";
import Logo from "../../assets/images/logo";

const { Header, Sider, Content } = Layout;

const AdminDashboard: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const [collapsed, setCollapsed] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState("");
  const user = useSelector(currentUserInfomation);
  const roles = useSelector(rolesSelectorList);
  const roleLoading = useSelector(rolesLoading);

  const search = useSelector(searchQuery);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  useEffect(() => {
    setCollapsed(isMobile);
    const pathKey = location.pathname.replace("/dashboard", "") || "/";

    setSelectedKey(pathKey);
  }, [isMobile, location.pathname]);

  const open = Boolean(anchorEl);
  const {
    token: { colorBgContainer },
  } = antTheme.useToken();

  const navigate = useNavigate();

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  const handleAntMenuClick: MenuProps["onClick"] = (e) => {
    const path = e.key === "/" ? "" : e.key;
    navigate(`/dashboard${path}`);
    setSelectedKey(e.key);
    if (isMobile) {
      toggleDrawer();
    }
  };

  if (!roles || roleLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spin size="large" />
      </Box>
    );
  }

  const getRoleData = (roleID: string | undefined): RoleModel | undefined => {
    if (!roleID || !Array.isArray(roles)) return undefined;
    return roles.find((role) => role.roleID === roleID);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearch(event.target.value));
  };

  const handleLogout = async () => {
    handleMenuClose();
    try {
      const resultAction = await dispatch(logoutStaff()).unwrap();
      if (logoutStaff.fulfilled.match(resultAction)) {
        logging.info("Logout successful!");
        navigate("/login");
      }
      // Navigate to the login page on success
    } catch (error) {
      console.error("Logout failed:", error);
      logging.error("Logout failed:", (error as Error).message);
    }
  };

  const roleName = getRoleData(user?.roleID)?.roleName || "ReadOnlyPartner";

  const menuItems: MenuProps["items"] = filterMenuItems(menuConfig, roleName);
  const drawerContent = (
    <Box sx={{ height: "100vh", bgcolor: "#001529", verflow: "auto" }}>
      <Box
        sx={{
          position: "sticky",
          top: 0, // Stick to the top of the sidebar
          zIndex: 1100, // Ensure it's above the scrolled content
          bgcolor: "inherit", // Maintain the sidebar color
          height: 62,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 2,
        }}
        mb={4}
        mt={2}
      >
        <Logo />
      </Box>
      <AntMenu
        theme="dark"
        mode="inline"
        selectedKeys={[selectedKey]}
        items={menuItems}
        onClick={handleAntMenuClick}
      />
    </Box>
  );
  return (
    <Layout>
      {isMobile ? (
        <Drawer
          anchor="left"
          open={mobileDrawerOpen}
          onClose={toggleDrawer}
          style={{ zIndex: 1300 }} // Ensure it is above AppBar
          ModalProps={{
            keepMounted: true, // Ensures elements are kept in the DOM when the drawer is closed
            disableEnforceFocus: true, // Prevents MUI from enforcing focus on the Drawer when open
          }}
          PaperProps={{
            "aria-hidden": !mobileDrawerOpen, // Dynamically set aria-hidden based on state
          }}
        >
          {drawerContent}
        </Drawer>
      ) : (
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
          }}
        >
          {drawerContent}
        </Sider>
      )}

      <Layout style={{ marginLeft: isMobile ? 0 : collapsed ? 80 : 250 }}>
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            mr={2}
            mt={1}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                ml: isMobile ? 0.1 : 2,
              }}
            >
              <MUIButton
                startIcon={
                  collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
                }
                onClick={() =>
                  isMobile ? toggleDrawer() : setCollapsed(!collapsed)
                }
              />
              <TextField
                variant="outlined"
                size="small"
                placeholder="Search..."
                value={search}
                onChange={handleSearchChange}
                sx={{
                  bgcolor: "lightgrey",
                  borderRadius: 1,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Box>
              <Box
                onClick={handleMenuClick}
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Avatar
                  src={user?.pictureUrl || ""}
                  sx={{ width: 40, height: 40, borderRadius: "10%" }}
                />

                {!isMobile && (
                  <Box sx={{ ml: 1, overflow: "hidden" }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: 140,
                        textTransform: "capitalize",
                      }}
                    >
                      {user?.firstName}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: 180,
                      }}
                    >
                      {user?.email}
                    </Typography>
                  </Box>
                )}
              </Box>

              <MuiMenu
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
              >
                <MenuItem component={Link} to="/dashboard/account">
                  View Profile
                </MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </MuiMenu>
            </Box>
          </Box>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            minHeight: 280,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminDashboard;
