import React, { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "./store"; // Import RootState if you have it defined to specify the type for the whole state
import { currentUserInfomation } from "./selectors/authSelectors";
import { rolesSelectorList } from "./selectors/roleSelector";

interface RequireRoleProps {
  children: ReactNode;
  allowedRoles: string[];
}

const RequireRole: React.FC<RequireRoleProps> = ({
  children,
  allowedRoles,
}) => {
  const location = useLocation();
  const user = useSelector(currentUserInfomation);
  const roles = useSelector(rolesSelectorList);

  // Redirect to login if no user is logged in
  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Ensure that roles data is loaded and is an array
  if (!Array.isArray(roles)) {
    console.error("Roles data is not available or not an array:", roles);
    return null; // You might want to handle this case differently
  }

  const allowedRolesSet = new Set(allowedRoles);

  // Determine if the user's role is in the list of allowed roles
  const userHasRequiredRole = roles.some(
    (role) => allowedRolesSet.has(role.roleName) && role.roleID === user.roleID
  );

  // Redirect to home page if user does not have the required role
  if (!userHasRequiredRole) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  // If user has the required role, render the children components
  return <>{children}</>;
};

export default RequireRole;
