import React from "react";
import {
  Typography,
  TextField,
  Box,
  Button,
  Modal,
  Backdrop,
  Fade,
  Alert,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"; // Importing Close icon
import { Formik, Form, FormikHelpers } from "formik";
import * as Yup from "yup";
import { ResetPasswordFormValues } from "../../../../@types/user-model";

const validationSchema = Yup.object({
  password: Yup.string()
    .required("Required")
    .min(8, "Password must be at least 8 characters long"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Required"),
  confirmationCode: Yup.string().required("Confirmation code is required"),
});

interface ResetPasswordModalProps {
  open: boolean;
  email: string;
  handleClose: () => void;
  handleSubmit: (values: ResetPasswordFormValues) => Promise<void>;
}

const ResetPasswordModal: React.FC<ResetPasswordModalProps> = ({
  open,
  email,
  handleClose,
  handleSubmit,
}) => {
  return (
    <Modal open={open} onClose={handleClose} closeAfterTransition>
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            Reset Password
          </Typography>
          <Alert severity="info" sx={{ mb: 2 }}>
            A confirmation code has been sent to your email address: {email} and
            it <strong>expires in 2 minutes</strong>.
          </Alert>
          <Formik
            initialValues={{
              password: "",
              confirmPassword: "",
              confirmationCode: "",
              email,
            }}
            validationSchema={validationSchema}
            onSubmit={async (
              values,
              { setSubmitting }: FormikHelpers<ResetPasswordFormValues>
            ) => {
              await handleSubmit(values);
              setSubmitting(false);
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur }) => (
              <Form>
                <TextField
                  margin="normal"
                  fullWidth
                  name="confirmationCode"
                  label="Confirmation Code"
                  type="text"
                  value={values.confirmationCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.confirmationCode && Boolean(errors.confirmationCode)
                  }
                  helperText={
                    touched.confirmationCode && errors.confirmationCode
                  }
                />
                <TextField
                  margin="normal"
                  fullWidth
                  name="password"
                  label="New Password"
                  type="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  name="confirmPassword"
                  label="Confirm New Password"
                  type="password"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.confirmPassword && Boolean(errors.confirmPassword)
                  }
                  helperText={touched.confirmPassword && errors.confirmPassword}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Reset Password
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ResetPasswordModal;
