import React, { useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "../../../../store";
import * as yup from "yup";

import { Formik, FormikHelpers, Form } from "formik";
import {
  dailyDrawWinnersResponse,
  monthlyDrawWinnersResponse,
  raffleLoadingState,
} from "../../../../selectors/raffleSelector";
import {
  ManualDailyWinnerResponse,
  ManualMonthlyWinnerResponse,
  RaffleWinnerModel,
} from "../../../../@types/raffle";
import {
  getAllRaffleWinners,
  initiateDailyDraws,
  initiateMonthlyDraws,
  maunalTicketPurchase,
} from "../../../../store/thunks/raffle-thunk";
import logging from "../../../../logging";
import { Spin } from "antd";
import { resetPreviousWinners } from "../../../../store/slices/raffle-slice";

const validationSchema = yup.object({
  triggerType: yup.string().required("Trigger type is required"),
  input: yup.string().required("Input is required"),
});

interface FormValues {
  triggerType: string; // 'monthly', 'daily', or 'transaction'
  input: string;
}

const ManualTriggers = () => {
  const dispatch: AppDispatch = useDispatch();

  const isLoading = useSelector(raffleLoadingState);
  const monthlyDrawResponse: ManualMonthlyWinnerResponse | null = useSelector(
    monthlyDrawWinnersResponse
  );
  const dailyDrawResponse: ManualDailyWinnerResponse | null = useSelector(
    dailyDrawWinnersResponse
  );

  const theme = useTheme();
  const [snackbarMessage, setSnackbarMessage] = React.useState<string | null>(
    null
  );

  useEffect(() => {
    dispatch(resetPreviousWinners());
  }, [dispatch]);

  const initialValues: FormValues = {
    triggerType: "",
    input: "",
  };

  const handleSubmit = (
    values: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    setSnackbarMessage(null);

    actions.setSubmitting(true);

    if (values.triggerType === "monthly") {
      handleMonthlyDraws(values.input);
    } else if (values.triggerType === "daily") {
      handleDailyDraws(values.input);
    } else if (values.triggerType === "transaction") {
      triggerTransaction(values.input);
    }

    actions.setSubmitting(false);
  };

  const handleMonthlyDraws = async (value: string): Promise<void> => {
    try {
      const action = initiateMonthlyDraws(value);
      const resultAction = await dispatch(action);

      if (
        typeof resultAction.payload === "string" ||
        "error" in resultAction.payload
      ) {
        logging.error(resultAction.payload);
      } else if (initiateMonthlyDraws.fulfilled.match(resultAction)) {
        dispatch(getAllRaffleWinners());
      } else {
        // Additional error handling if needed
        logging.error(resultAction.error);
      }
    } catch (error) {
      logging.error("Dispatch failed:", (error as Error).message);
    }
  };
  const handleDailyDraws = async (value: string): Promise<void> => {
    try {
      const action = initiateDailyDraws(value);
      const resultAction = await dispatch(action);

      if (
        typeof resultAction.payload === "string" ||
        "error" in resultAction.payload
      ) {
        logging.error(resultAction.payload);
      } else if (initiateDailyDraws.fulfilled.match(resultAction)) {
        dispatch(getAllRaffleWinners());
      } else {
        // Additional error handling if needed
        logging.error(resultAction.error);
      }
    } catch (error) {
      logging.error("Dispatch failed:", (error as Error).message);
    }
  };

  const triggerTransaction = async (value: string): Promise<void> => {
    try {
      const action = maunalTicketPurchase(value);
      const resultAction = await dispatch(action);

      if (
        typeof resultAction.payload === "string" ||
        "error" in resultAction.payload
      ) {
        logging.error(resultAction.payload);
      } else if (maunalTicketPurchase.fulfilled.match(resultAction)) {
        await dispatch(getAllRaffleWinners());
        setSnackbarMessage("Ticket created and information sent to owner");
      } else {
        // Additional error handling if needed
        logging.error(resultAction.error);
      }
    } catch (error) {
      logging.error("Dispatch failed:", (error as Error).message);
    }
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spin size="large" />
      </Box>
    );
  }

  const winners =
    monthlyDrawResponse?.monthLyWinners || dailyDrawResponse?.dailyWinners;

  return (
    <Box padding={theme.spacing(3)}>
      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={6000}
        onClose={() => setSnackbarMessage(null)}
        message={snackbarMessage}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            gutterBottom
            component="div"
            sx={{ fontWeight: "bold" }}
          >
            Manul Triggers
          </Typography>
        </Grid>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          validateOnBlur={true}
          validateOnChange={true}
          enableReinitialize={true}
        >
          {({ values, handleChange, setFieldValue }) => (
            <Form>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="legend">Select Trigger Type</FormLabel>
                <RadioGroup
                  row
                  name="triggerType"
                  value={values.triggerType}
                  onChange={(event) => {
                    setFieldValue("triggerType", event.target.value);
                    setFieldValue("input", ""); // Clear the input field
                  }}
                >
                  <FormControlLabel
                    value="monthly"
                    control={<Radio />}
                    label="Monthly Draw"
                  />
                  <FormControlLabel
                    value="daily"
                    control={<Radio />}
                    label="Daily Draw"
                  />
                  <FormControlLabel
                    value="transaction"
                    control={<Radio />}
                    label="Ticket Purchase"
                  />
                </RadioGroup>
              </FormControl>
              {values.triggerType && (
                <TextField
                  fullWidth
                  label={
                    values.triggerType === "transaction"
                      ? "Transaction Reference"
                      : "Admin Secret"
                  }
                  name="input"
                  value={values.input}
                  onChange={handleChange}
                  margin="normal"
                  variant="outlined"
                />
              )}
              <Box mt={5}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isLoading}
                >
                  Trigger
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
        {winners && (
          <Card sx={{ mt: 2 }}>
            <CardContent>
              <Typography color="primary" sx={{ mb: 2 }}>
                Winners List:
              </Typography>
              <List>
                {winners.map((winner: RaffleWinnerModel) => (
                  <ListItem key={winner.winnerID}>
                    <ListItemText
                      primary={`Winner ID: ${winner.winnerID}, Phone: ${winner.winnerPhoneNumber}`}
                      secondary={`Prize: ${winner.price}, Raffle ID: ${winner.raffleID}`}
                    />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        )}
      </Grid>
    </Box>
  );
};

export default ManualTriggers;
