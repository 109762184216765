import React, { useEffect, useMemo, useState } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import {
  usersSelectorList,
  usersSelectorLoadingState,
} from "../../../../selectors/userSelector";
// import { UserModel } from "../../../../@types/user-model";
import ReusableTable from "../../../../components/reusable-table";
import { CustomColumn } from "../../../../@types/table";
import { searchQuery } from "../../../../selectors/seacrhSelector";
import { resetSearch } from "../../../../store/slices/search-slice";
import { AppDispatch } from "../../../../store";
// import { WalletInfo } from "../../../../@types/wallet";
// import { useNavigate } from "react-router-dom";
import { RaffleTicketModel } from "../../../../@types/raffle";
import dayjs from "dayjs";
import { raffleTicketsList } from "../../../../selectors/raffleSelector";
import { Button } from "antd";

const RaffleTickets = () => {
  const dispatch: AppDispatch = useDispatch();
  // const navigate = useNavigate();
  const raffleTicketsData: RaffleTicketModel[] | null =
    useSelector(raffleTicketsList);
  const [sortDirection, setSortDirection] = useState("descending");

  const isLoading = useSelector(usersSelectorLoadingState);
  const search = useSelector(searchQuery);

  const theme = useTheme();

  const columns: CustomColumn<RaffleTicketModel>[] = useMemo(
    () => [
      { id: "raffleID", label: "Raffle ID" },
      { id: "channelID", label: "Raffle Type" },
      { id: "userPhoneNumber", label: "User" },
      {
        id: "raffleDate",
        label: "Date",
        format: (value: Date) => dayjs(value).format("YYYY-MM-DD"),
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(resetSearch());
  }, [dispatch]);

  const filteredWallets = useMemo(() => {
    if (!search) return raffleTicketsData;
    return raffleTicketsData.filter(
      (raffle) =>
        raffle.channelID.toLowerCase().includes(search.toLowerCase()) ||
        raffle.userPhoneNumber.toLowerCase().includes(search.toLowerCase()) ||
        raffle.raffleID.toLowerCase().includes(search.toLowerCase())
    );
  }, [raffleTicketsData, search]);

  const sortedAndFilteredWallets = useMemo(() => {
    let sortableItems = raffleTicketsData ? [...raffleTicketsData] : [];
    if (search) {
      const searchLower = search.toLowerCase(); // Convert search term to lowercase once
      sortableItems = sortableItems.filter(
        (raffle) =>
          raffle.channelID.toLowerCase().includes(searchLower) ||
          raffle.userPhoneNumber.toLowerCase().includes(searchLower) ||
          raffle.raffleID.toString().toLowerCase().includes(searchLower)
      );
    }

    // Sorting logic
    sortableItems.sort((a, b) => {
      if (a.raffleDate < b.raffleDate) {
        return sortDirection === "ascending" ? -1 : 1;
      }
      if (a.raffleDate > b.raffleDate) {
        return sortDirection === "ascending" ? 1 : -1;
      }
      return 0;
    });

    return sortableItems;
  }, [raffleTicketsData, search, sortDirection]);

  // Function to toggle sort direction
  const toggleSortDirection = () => {
    setSortDirection((prevDirection) =>
      prevDirection === "ascending" ? "descending" : "ascending"
    );
  };

  return (
    <Box padding={theme.spacing(3)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box
            justifyContent="space-between"
            display="flex"
            alignItems="center"
          >
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              sx={{ fontWeight: "bold" }}
            >
              Raffle Tickets
            </Typography>
            <Button onClick={toggleSortDirection}>Toggle Date Sort</Button>

            <Typography
              variant="h4"
              gutterBottom
              component="div"
              sx={{ fontWeight: "bold" }}
            >
              {filteredWallets.length}
            </Typography>
          </Box>
        </Grid>
        {isLoading ? (
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <Typography> Loading...</Typography>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <ReusableTable
              data={sortedAndFilteredWallets}
              columns={columns}
              paginationOptions={{
                rowsPerPage: 20,
                rowsPerPageOptions: [10, 20, 30],
                showPagination: true,
              }}
              enableRowClick={true}
              // onRowClick={(item) => handleUserClick(item)}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default RaffleTickets;
