import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { Column as AntsColumnPlot, Line } from "@ant-design/plots";

import FixInfoCard from "../info-fix-card";
import { useSelector } from "react-redux";
import {
  raffleTicketsList,
  raffleTransactions,
  raffleWinners,
} from "../../../../selectors/raffleSelector";
import { formatCurrency } from "../../../../utils";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { RaffleTicketModel } from "../../../../@types/raffle";
dayjs.extend(isSameOrBefore);
dayjs.extend(weekOfYear);

const today = dayjs().startOf("day");
const endOfDay = dayjs().endOf("day");
const yesterdayStart = dayjs().subtract(1, "day").startOf("day");
const yesterdayEnd = dayjs().subtract(1, "day").endOf("day");

const startOfWeek = dayjs().startOf("week");
const endOfWeek = dayjs().endOf("week");
const startOfMonth = dayjs().startOf("month");
const endOfMonth = dayjs().endOf("month");

// Calculate last week and last month
const lastWeekStart = dayjs().subtract(1, "week").startOf("week");
const lastWeekEnd = dayjs().subtract(1, "week").endOf("week");
const lastMonthStart = dayjs().subtract(1, "month").startOf("month");
const lastMonthEnd = dayjs().subtract(1, "month").endOf("month");

interface TicketCountPerChannel {
  [key: string]: number;
}

interface BaseTrendData {
  time: string; // Standardized time field for both day and hour specifics
  value: number;
}

interface ChartDataItem {
  category: string;
  value: number;
}
interface HourlySalesData {
  hour: string;
  value: number;
  day: string;
}

interface DailyTrendData extends BaseTrendData {
  day: string;
}

interface HourlyTrendData extends BaseTrendData {
  hour: string;
}

type TrendData = DailyTrendData | HourlyTrendData;

const RaffleDashboard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const raffleTicketsData = useSelector(raffleTicketsList) || [];
  const raffleWinnersData = useSelector(raffleWinners) || [];
  // const raffleTransactionsData = useSelector(raffleTransactions) || [];

  const channelPrices: { [key: string]: number } = {
    channel_500: 500,
    channel_100: 100,
    channel_200: 200,
  };

  const totalAmountFromTickets = raffleTicketsData.reduce((acc, ticket) => {
    const ticketPrice = channelPrices[ticket.channelID] || 0; // Default to 0 if channel not found
    return acc + ticketPrice;
  }, 0);

  const ticketCountPerChannel = raffleTicketsData.reduce<TicketCountPerChannel>(
    (acc, ticket) => {
      acc[ticket.channelID] = (acc[ticket.channelID] || 0) + 1;
      return acc;
    },
    {}
  );

  const revenuePerChannel = Object.keys(ticketCountPerChannel).reduce(
    (acc, channelID) => {
      acc[channelID] =
        (ticketCountPerChannel[channelID] || 0) *
        (channelPrices[channelID] || 0);
      return acc;
    },
    {} as { [key: string]: number }
  );

  // Calculate total amount expected to be paid out to winners (unpaid prizes)
  const totalAmountExpectedToPayOut = raffleWinnersData.reduce(
    (acc, winner) => {
      if (!winner.paymentPaid) {
        return acc + winner.price;
      }
      return acc;
    },
    0
  );

  // Calculate total amount actually paid out (paid prizes)
  const totalAmountPaidOut = raffleWinnersData.reduce((acc, winner) => {
    if (winner.paymentPaid) {
      return acc + winner.price;
    }
    return acc;
  }, 0);

  // Calculate amount received per channel
  // const amountReceivedPerChannel =
  //   raffleTransactionsData.reduce<TicketCountPerChannel>((acc, transaction) => {
  //     if (
  //       transaction.status === "successful" ||
  //       transaction.status === "success"
  //     ) {
  //       const { channelID, amount } = transaction;
  //       acc[channelID] = (acc[channelID] || 0) + amount;
  //     }
  //     return acc;
  //   }, {});

  const getTicketsForPeriod = (
    tickets: RaffleTicketModel[],
    start: dayjs.Dayjs,
    end: dayjs.Dayjs
  ): RaffleTicketModel[] => {
    return tickets.filter(
      (ticket) =>
        dayjs(ticket.raffleDate).isSameOrBefore(end) &&
        dayjs(ticket.raffleDate).isSameOrAfter(start)
    );
  };
  // Assuming you have initialized dayjs with necessary plugins and the raffleTicketsData is properly typed
  const countTicketsPerChannel = (
    tickets: RaffleTicketModel[]
  ): TicketCountPerChannel => {
    return tickets.reduce((acc, ticket) => {
      acc[ticket.channelID] = (acc[ticket.channelID] || 0) + 1;
      return acc;
    }, {} as TicketCountPerChannel);
  };

  const ticketsToday: RaffleTicketModel[] = getTicketsForPeriod(
    raffleTicketsData,
    today,
    endOfDay
  );
  const ticketsTodayCount: TicketCountPerChannel =
    countTicketsPerChannel(ticketsToday);

  const ticketsThisMonth: RaffleTicketModel[] = getTicketsForPeriod(
    raffleTicketsData,
    startOfMonth,
    endOfMonth
  );
  const ticketsLastMonth: RaffleTicketModel[] = getTicketsForPeriod(
    raffleTicketsData,
    lastMonthStart,
    lastMonthEnd
  );
  const ticketsThisWeek: RaffleTicketModel[] = getTicketsForPeriod(
    raffleTicketsData,
    startOfWeek,
    endOfWeek
  );
  const ticketsLastWeek: RaffleTicketModel[] = getTicketsForPeriod(
    raffleTicketsData,
    lastWeekStart,
    lastWeekEnd
  );
  const ticketsYesterday: RaffleTicketModel[] = getTicketsForPeriod(
    raffleTicketsData,
    yesterdayStart,
    yesterdayEnd
  );

  const ticketsYesterdayCount: TicketCountPerChannel =
    countTicketsPerChannel(ticketsYesterday);

  const chartData = Object.keys(ticketCountPerChannel).flatMap((channelID) => [
    {
      month: "This Week",
      type: `Channel ${channelID}`,
      value: ticketsThisWeek.filter((ticket) => ticket.channelID === channelID)
        .length,
    },
    {
      month: "Last Week",
      type: `Channel ${channelID}`,
      value: ticketsLastWeek.filter((ticket) => ticket.channelID === channelID)
        .length,
    },
  ]);

  const chartMonthlyData = Object.keys(ticketCountPerChannel).flatMap(
    (channelID) => [
      {
        month: "This Week",
        type: `Channel ${channelID}`,
        value: ticketsThisMonth.filter(
          (ticket) => ticket.channelID === channelID
        ).length,
      },
      {
        month: "Last Week",
        type: `Channel ${channelID}`,
        value: ticketsLastMonth.filter(
          (ticket) => ticket.channelID === channelID
        ).length,
      },
    ]
  );

  const yesterdayTodayChartData = Object.keys(ticketCountPerChannel).flatMap(
    (channelID) => [
      {
        day: "Today",
        type: `Channel ${channelID}`,
        value: ticketsToday.filter((ticket) => ticket.channelID === channelID)
          .length,
      },
      {
        day: "Yesterday",
        type: `Channel ${channelID}`,
        value: ticketsYesterday.filter(
          (ticket) => ticket.channelID === channelID
        ).length,
      },
    ]
  );
  // Group tickets by hour for a specific date
  const groupTicketsByHour = (
    tickets: RaffleTicketModel[],
    date: dayjs.Dayjs
  ) => {
    const hours = Array.from({ length: 24 }, (_, i) => i); // Create an array of hours (0-23)
    return hours.map((hour) => ({
      hour: `${hour}:00`,
      value: tickets.filter((ticket) =>
        dayjs(ticket.raffleDate).isSame(date.hour(hour), "hour")
      ).length,
    }));
  };

  // Today and Yesterday Trend (Hourly)
  const todayTrend = groupTicketsByHour(raffleTicketsData, today).map(
    (item) => ({
      ...item,
      time: item.hour, // Use time field for consistency
    })
  );

  const yesterdayTrend = groupTicketsByHour(
    raffleTicketsData,
    yesterdayStart
  ).map((item) => ({
    ...item,
    time: item.hour,
  }));

  const totalAmountToday = ticketsToday.reduce((acc, ticket) => {
    const ticketPrice = channelPrices[ticket.channelID] || 0;
    return acc + ticketPrice;
  }, 0);

  const totalAmountYesterday = ticketsYesterday.reduce((acc, ticket) => {
    const ticketPrice = channelPrices[ticket.channelID] || 0;
    return acc + ticketPrice;
  }, 0);
  const amountComparisonData = [
    {
      day: "Today",
      totalAmount: totalAmountToday,
    },
    {
      day: "Yesterday",
      totalAmount: totalAmountYesterday,
    },
  ];

  const gainLossPercentage =
    totalAmountFromTickets > 0
      ? ((totalAmountFromTickets - totalAmountPaidOut) /
          totalAmountFromTickets) *
        100
      : -100; // If no revenue, it's a 100% loss

  const countTicketsPerUser = (tickets: RaffleTicketModel[]) => {
    return tickets.reduce(
      (acc, ticket) => {
        const userKey = ticket.userPhoneNumber; // Use phone number as the key
        acc[userKey] = (acc[userKey] || 0) + 1;
        return acc;
      },
      {} as { [userPhoneNumber: string]: number }
    );
  };

  const categorizeUserPurchases = (ticketCounts: {
    [userPhoneNumber: string]: number;
  }) => {
    const categories = {
      "1": 0,
      "2-3": 0,
      "4-5": 0,
      "6-10": 0,
      "11-20": 0,
      "21-50": 0,
      "51+": 0,
    };

    Object.values(ticketCounts).forEach((count) => {
      if (count === 1) categories["1"] += 1;
      else if (count >= 2 && count <= 3) categories["2-3"] += 1;
      else if (count >= 4 && count <= 5) categories["4-5"] += 1;
      else if (count >= 6 && count <= 10) categories["6-10"] += 1;
      else if (count >= 11 && count <= 20) categories["11-20"] += 1;
      else if (count >= 21 && count <= 50) categories["21-50"] += 1;
      else categories["51+"] += 1;
    });

    return categories;
  };

  const ticketCounts = countTicketsPerUser(raffleTicketsData);
  const purchaseCategories = categorizeUserPurchases(ticketCounts);

  const chartFreqData: ChartDataItem[] = Object.entries(purchaseCategories).map(
    ([category, value]) => ({
      category,
      value,
    })
  );

  const groupTicketsByDayAndHour = (
    tickets: RaffleTicketModel[]
  ): HourlySalesData[] => {
    let hourlyWeekData: HourlySalesData[] = [];

    for (let i = 0; i < 7; i++) {
      // Loop through each day of the last week
      const dayStart = lastWeekStart.add(i, "day");
      const dayLabel = dayStart.format("dddd"); // Day name like 'Monday'

      for (let hour = 0; hour < 24; hour++) {
        // 24 hours
        const hourLabel = `${hour}:00`;
        const hourStart = dayStart.hour(hour);
        const hourEnd = hourStart.add(1, "hour");

        const hourlyCount = tickets.filter((ticket) => {
          const ticketDate = dayjs(ticket.raffleDate);
          return ticketDate.isAfter(hourStart) && ticketDate.isBefore(hourEnd);
        }).length;

        hourlyWeekData.push({
          hour: hourLabel,
          value: hourlyCount,
          day: dayLabel,
        });
      }
    }

    return hourlyWeekData;
  };

  const getUniqueUserCount = (tickets:RaffleTicketModel[]) => {
    const uniqueUsers = new Set();
    tickets.forEach(ticket => {
      if (ticket.userPhoneNumber) {
        uniqueUsers.add(ticket.userPhoneNumber);
      }
    });
    return uniqueUsers.size; // Returns the count of unique phone numbers
  };
  

  const uniqueUserCount = getUniqueUserCount(raffleTicketsData); 

  const dailyTicketCounts = (tickets: RaffleTicketModel[]) => {
    const dailyCounts = Array(7).fill(0);
    tickets.forEach((ticket) => {
      const dayIndex = dayjs(ticket.raffleDate).day() - lastWeekStart.day();
      if (dayIndex >= 0 && dayIndex < 7) {
        dailyCounts[dayIndex]++;
      }
    });
    return dailyCounts.map((count, index) => ({
      day: lastWeekStart.add(index, "day").format("dddd"),
      value: count,
    }));
  };

  const lastWeekTickets = getTicketsForPeriod(
    raffleTicketsData,
    lastWeekStart,
    lastWeekEnd
  );

  const hourlyData = groupTicketsByDayAndHour(lastWeekTickets);
  const dailyData = dailyTicketCounts(lastWeekTickets);

  return (
    <Box sx={{ p: isMobile ? 1 : 3 }}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
        mb={3}
      >
        <Grid item xs={12} sm={6}>
          <Typography
            variant="h4"
            gutterBottom
            component="div"
            sx={{ fontWeight: "bold" }}
          >
            Raffle Dashboard
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={5}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Ticket Sales Weekly Analysis </Typography>
          <AntsColumnPlot
            data={chartData}
            isGroup
            xField="type"
            yField="value"
            seriesField="month"
            colorField={({ month }: { month: string }) =>
              month === "This Week" ? "#1890ff" : "#facc14"
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Ticket Sales Monthly Analysis </Typography>
          <AntsColumnPlot
            data={chartMonthlyData}
            isGroup
            xField="type"
            yField="value"
            seriesField="month"
            colorField={({ month }: { month: string }) =>
              month === "This Week" ? "#1890ff" : "#facc14"
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Ticket Sales: Today vs Yesterday</Typography>
          <AntsColumnPlot
            data={yesterdayTodayChartData}
            isGroup
            xField="type"
            yField="value"
            seriesField="day"
            colorField={({ day }: { day: string }) =>
              day === "Today" ? "#52c41a" : "#faad14"
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">
            Total Amount Made: Today vs Yesterday
          </Typography>
          <AntsColumnPlot
            data={amountComparisonData}
            xField="day"
            yField="totalAmount"
            colorField="day"
            columnStyle={({ day }: { day: string }) =>
              day === "Today" ? { fill: "#52c41a" } : { fill: "#faad14" }
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={3}>
        {/* Today vs Yesterday by Hours */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Today vs Yesterday Hourly</Typography>
          <Line
            data={[...todayTrend, ...yesterdayTrend].map((item, index) => ({
              ...item,
              day: index < todayTrend.length ? "Today" : "Yesterday",
            }))}
            xField="time"
            yField="value"
            seriesField="day"
            smooth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">User Participation Frequency</Typography>
          <AntsColumnPlot
            data={chartFreqData}
            xField="category"
            yField="value"
            colorField="category"
            isGroup
            columnStyle={({ category }: ChartDataItem) => ({
              fill: category.includes("+") ? "#fa541c" : "#1890ff",
            })}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6">
            Hourly Ticket Sales Last Week by Day
          </Typography>
          <Line
            data={hourlyData}
            xField="hour"
            yField="value"
            seriesField="day"
            smooth
            color={[
              "#7189bf",
              "#df7599",
              "#ffc785",
              "#72d6c9",
              "#f3a683",
              "#70a1ff",
              "#7bed9f",
            ]} // Example colors for each day
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6">Daily Ticket Sales Last Week</Typography>
          <AntsColumnPlot
            data={dailyData}
            xField="day"
            yField="value"
            colorField="day"
            isGroup
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} marginBottom={4}>
        <Grid item xs={12} sm={6} md={4}>
          <FixInfoCard
            info="Total Revenue (From Tickets)"
            value={formatCurrency(totalAmountFromTickets, true)}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FixInfoCard
            info="Revenue Today"
            value={formatCurrency(totalAmountToday, true)}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FixInfoCard
            info="Revenue Yesterday"
            value={formatCurrency(totalAmountYesterday, true)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FixInfoCard
            info="Total Raffle Winners"
            value={raffleWinnersData.length.toString()}
          />{" "}
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FixInfoCard
            info="Total Raffle Tickets"
            value={raffleTicketsData.length.toString()}
          />
        </Grid>
        <Grid item xs={12} md={4} mb={2}>
          <FixInfoCard
            info="Gain/Loss Percentage"
            value={
              gainLossPercentage !== 0
                ? `${gainLossPercentage > 0 ? "+" : ""}${gainLossPercentage.toFixed(
                    2
                  )}%`
                : "N/A"
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} mb={2}>
          <FixInfoCard
            info="Total Expected Payout"
            value={formatCurrency(totalAmountExpectedToPayOut, true)}
          />
        </Grid>
        <Grid item xs={12} md={4} mb={2}>
          <FixInfoCard
            info="Total Paid Out"
            value={formatCurrency(totalAmountPaidOut, true)}
          />
        </Grid>
        <Grid item xs={12} md={4} mb={2}>          
        <FixInfoCard
            info="Total Unique Users"
            value={uniqueUserCount.toString()}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} marginBottom={4}>
        {Object.keys(revenuePerChannel).map((channelID) => (
          <Grid key={channelID} item xs={12} sm={6} md={4}>
            <FixInfoCard
              info={`Channel ${channelID} Revenue`}
              value={formatCurrency(revenuePerChannel[channelID], true)}
            />
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={2}>
        {Object.entries(ticketsTodayCount).map(([channelID, count]) => (
          <Grid key={channelID} item xs={12} sm={6} md={4}>
            <FixInfoCard
              info={`Channel ${channelID} Tickets Today`}
              value={count.toString()}
            />
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={2} marginBottom={4}>
        {Object.keys(ticketCountPerChannel).map((channelID) => (
          <Grid key={channelID} item xs={12} sm={6} md={4}>
            <FixInfoCard
              info={`Channel ${channelID}`}
              value={ticketCountPerChannel[channelID].toString()}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
export default RaffleDashboard;
