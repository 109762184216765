import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  SxProps,
  Theme,
  Icon,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PreviewIcon from "@mui/icons-material/Preview";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
interface HeaderActionsProps {
  title: string;
  onEdit?: () => void;
  triggerTitle: string;
  titleColor?: string;
  titleFontSize?: string | number;
  titleFontWeight?: number | string;
  iconColor?: string;
  profitChange?: "increase" | "decrease";
  sxTitle?: SxProps<Theme>;
  sxIcon?: SxProps<Theme>;
  showMore?: boolean;
}

const HeaderActions: React.FC<HeaderActionsProps> = ({
  title,
  onEdit,
  triggerTitle,
  titleColor = "inherit",
  titleFontSize = 16,
  titleFontWeight = "bold",
  iconColor = "grey",
  profitChange,
  sxTitle = {},
  sxIcon = {},
  showMore = true,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const ProfitIcon =
    profitChange === "increase" ? TrendingUpIcon : TrendingDownIcon;
  const currentIconColor = profitChange === "increase" ? "green" : "red"; // Use a different local variable

  return (
    <Box
      mb={1}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          fontSize: titleFontSize,
          fontWeight: titleFontWeight,
          color: titleColor,
          ...sxTitle,
        }}
      >
        {title}
      </Typography>
      {profitChange && (
        <Icon sx={{ color: currentIconColor, ...sxIcon }}>
          <ProfitIcon />
        </Icon>
      )}
      {showMore && (
        <Box>
          <IconButton onClick={handleClick} sx={sxIcon}>
            <MoreVertIcon sx={{ color: iconColor }} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                onEdit?.();
              }}
            >
              <ListItemIcon>
                <PreviewIcon />
              </ListItemIcon>
              <ListItemText>{triggerTitle}</ListItemText>
            </MenuItem>
            {/* Additional menu items can be added here */}
          </Menu>
        </Box>
      )}
    </Box>
  );
};

export default HeaderActions;
