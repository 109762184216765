import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  useTheme,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@mui/material";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import logging from "../../../../logging";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store";
import { useNavigate, useParams } from "react-router-dom";

import CustomSnackbar from "../../../../components/custom-snackbar.tsx";
import {
  rolesCreationLError,
  rolesLoading,
  rolesMessageData,
  rolesSelectorList,
} from "../../../../selectors/roleSelector";

import { Spin } from "antd";
import { RoleModel } from "../../../../@types/roles-model";
import {
  addNewRoles,
  getRoles,
  updateRole,
} from "../../../../store/thunks/roles-thunks";

type PermissionKeys = "read" | "write" | "delete" | "viewLogs" | "manageUsers";

const initialPermissions: Record<PermissionKeys, boolean> = {
  read: false,
  write: false,
  delete: false,
  manageUsers: false,
  viewLogs: false,
};

const roleValidationSchema = yup.object({
  roleName: yup.string().required("Role name is required"),
  permissions: yup
    .object()
    .test(
      "hasAtLeastOnePermission",
      "At least one permission is required",
      (permissions) => Object.values(permissions || {}).some((value) => value)
    ),
});

const EditRole: React.FC = () => {
  const { roleId } = useParams<{ roleId: string }>();

  const theme = useTheme();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const isLoading = useSelector(rolesLoading);

  const error = useSelector(rolesCreationLError);
  const message = useSelector(rolesMessageData);
  const roles = useSelector(rolesSelectorList);
  const roleDetails = roles.find((role) => role.roleID === roleId);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState<"success" | "error">(
    "success"
  );
  const [formValues, setFormValues] = useState({
    roleName: "",
    permissions: {
      read: false,
      write: false,
      delete: false,
      manageUsers: false,
      viewLogs: false,
    },
  });

  useEffect(() => {
    if (roleDetails) {
      setFormValues({
        roleName: roleDetails.roleName,
        permissions: {
          read: roleDetails.permissions.read ?? false,
          write: roleDetails.permissions.write ?? false,
          delete: roleDetails.permissions.delete ?? false,
          manageUsers: roleDetails.permissions.manageUsers ?? false,
          viewLogs: roleDetails.permissions.viewLogs ?? false,
        },
      });
    }
  }, [roleDetails]);

  useEffect(() => {
    if (message) {
      setSnackbarMessage(message);
      setAlertSeverity("success");
      setOpenSnackbar(true);
    } else if (error) {
      setSnackbarMessage(error);
      setAlertSeverity("error");
      setOpenSnackbar(true);
    }
  }, [dispatch, message, error]);

  const handleSubmit = async (values: Partial<RoleModel>) => {
    const updatedValues = { ...values, roleID: roleId };

    try {
      const action = updateRole(updatedValues);
      const resultAction = await dispatch(action);
      // Check if it's an error message/object based on some criterion
      if (
        typeof resultAction.payload === "string" ||
        "error" in resultAction.payload
      ) {
        logging.error(resultAction.payload);
      } else if (addNewRoles.fulfilled.match(resultAction)) {
        navigate(`/dashboard`);
        dispatch(getRoles());
        logging.info("Role updated successful!");
      } else {
        // Additional error handling if needed
        logging.error(resultAction.error);
      }
    } catch (error) {
      logging.error("Dispatch failed:", (error as Error).message);
    }
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spin size="large" />
      </Box>
    );
  }

  return (
    <Box padding={theme.spacing(3)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            gutterBottom
            component="div"
            sx={{ fontWeight: "bold" }}
          >
            Edit Role
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Box sx={{ maxWidth: 400, m: "auto", p: 3 }}>
              <Formik
                initialValues={formValues}
                validationSchema={roleValidationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
                validateOnBlur={false}
              >
                {({ values, handleChange, isSubmitting }) => (
                  <Form noValidate>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Field
                          name="roleName"
                          as={TextField}
                          label="Role Name"
                          variant="outlined"
                          fullWidth
                          helperText={
                            <ErrorMessage name="roleName">
                              {(msg) => (
                                <Typography color="error">{msg}</Typography>
                              )}
                            </ErrorMessage>
                          }
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                          Permissions
                        </Typography>
                        {Object.keys(initialPermissions).map((permission) => (
                          <FormControlLabel
                            key={permission}
                            control={
                              <Checkbox
                                name={`permissions.${permission}`}
                                checked={
                                  values.permissions[
                                    permission as keyof typeof values.permissions
                                  ]
                                }
                                onChange={handleChange}
                              />
                            }
                            label={permission
                              .replace(/([A-Z])/g, " $1")
                              .toUpperCase()}
                          />
                        ))}
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting || isLoading}
                        >
                          Save Changes
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
              <CustomSnackbar
                message={snackbarMessage}
                severity={alertSeverity}
                open={openSnackbar}
                handleClose={() => setOpenSnackbar(false)}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditRole;
