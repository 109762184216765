import { Box, Typography } from "@mui/material";
import HeaderActions from "../../../../components/header-action";
import { TrendingDown, TrendingUp } from "@mui/icons-material";
import React from "react";

interface InfoCardProps {
  value: string;
  change: string;
  decrease?: boolean;
  info: string;
  date: string;
  onEdit: () => void;
}

const InfoCard: React.FC<InfoCardProps> = ({
  value,
  change,
  decrease,
  info,
  date,
  onEdit,
}) => {
  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        p: 2,
        width: 1,
        border: 1, // Adds border
        borderColor: "grey.300", // Border color
        borderRadius: 2, // Rounded corners
      }}
    >
      <HeaderActions
        title={info}
        triggerTitle="View"
        titleColor="grey"
        titleFontSize="13px"
        titleFontWeight="600"
        iconColor="grey"
        onEdit={onEdit}
        sxIcon={{ transform: "scale(1.2)" }}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">{value}</Typography>
        <Box sx={{ textAlign: "right" }}>
          <Typography color={decrease ? "error" : "green"}>
            {decrease ? <TrendingDown /> : <TrendingUp />} {change}
          </Typography>
          <Typography
            sx={{ color: "grey", fontSize: 12, fontWeight: "500" }}
            variant="body2"
          >
            Compared to {date}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default InfoCard;
