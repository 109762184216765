import { createAsyncThunk } from "@reduxjs/toolkit";
import staffService from "../../services/staff-service";
import {
  requestTokenToEmail,
  StaffModel,
  StaffResetModel,
} from "../../@types/staff-model";
import { AxiosError } from "axios";
import { ErrorResponse } from "../../@types/common";
import logging from "../../logging";

export const registerStaff = createAsyncThunk<
  any,
  StaffModel,
  { rejectValue: string }
>("auth/registerStaff", async (data: StaffModel, thunkAPI) => {
  try {
    return await staffService.registerStaff(data);
  } catch (error) {
    const axiosError = error as AxiosError;
    const data = axiosError.response?.data as ErrorResponse;
    const serverErrorMessage = data?.error ?? "Something bad went wrong";

    logging.error("Error logging in staff:", serverErrorMessage);
    return thunkAPI.rejectWithValue(serverErrorMessage);
  }
});

export const getStaffs = createAsyncThunk<StaffModel[], void>(
  "staff/getStaffs",
  async (_, thunkAPI) => {
    try {
      return await staffService.fetchStaffs();
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as ErrorResponse;
      const serverErrorMessage = data?.error ?? "Something bad went wrong";

      logging.error("Failed to fetch all staffs list:", serverErrorMessage);
      return thunkAPI.rejectWithValue({ message: serverErrorMessage });
    }
  }
);

export const updateStaff = createAsyncThunk<any, Partial<StaffModel>>(
  "auth/updateStaff",
  async (data: Partial<StaffModel>, thunkAPI) => {
    try {
      return await staffService.updateStaff(data);
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as ErrorResponse;
      const serverErrorMessage = data?.error ?? "Something bad went wrong";

      logging.error("Error updating staff info:", serverErrorMessage);

      // Reject the thunk action with the server error message
      return thunkAPI.rejectWithValue({ message: serverErrorMessage });
    }
  }
);

export const changeStaffPassowrd = createAsyncThunk<any, Partial<StaffModel>>(
  "auth/changeStaffPassowrd",
  async (data: Partial<StaffModel>, thunkAPI) => {
    try {
      return await staffService.changePassword(data);
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as ErrorResponse;
      const serverErrorMessage = data?.error ?? "Something bad went wrong";

      logging.error("Error changing password:", serverErrorMessage);

      // Reject the thunk action with the server error message
      return thunkAPI.rejectWithValue({ message: serverErrorMessage });
    }
  }
);

export const sendPasswordResetToken = createAsyncThunk<
  any,
  requestTokenToEmail
>(
  "auth/sendPasswordResetToken",
  async (data: requestTokenToEmail, thunkAPI) => {
    try {
      return await staffService.sendResetPasswordToken(data);
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as ErrorResponse;
      const serverErrorMessage = data?.error ?? "Something bad went wrong";

      logging.error("Error sending passowrd link:", serverErrorMessage);

      // Reject the thunk action with the server error message
      return thunkAPI.rejectWithValue({ message: serverErrorMessage });
    }
  }
);

export const resetPassword = createAsyncThunk<any, StaffResetModel>(
  "auth/resetPassword",
  async (data: StaffResetModel, thunkAPI) => {
    try {
      return await staffService.resetPassord(data);
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as ErrorResponse;
      const serverErrorMessage = data?.error ?? "Something bad went wrong";

      logging.error("Error resetting passowrd:", serverErrorMessage);

      // Reject the thunk action with the server error message
      return thunkAPI.rejectWithValue({ message: serverErrorMessage });
    }
  }
);

export const updateStaffProfilePicture = createAsyncThunk<any, FormData>(
  "auth/updatePicture",
  async (data: FormData, thunkAPI) => {
    try {
      return await staffService.updateStaffProfilePicture(data);
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as ErrorResponse;
      const serverErrorMessage = data?.error ?? "Something bad went wrong";

      logging.error(
        "Error updating staff profile picture:",
        serverErrorMessage
      );

      // Reject the thunk action with the server error message
      return thunkAPI.rejectWithValue({ message: serverErrorMessage });
    }
  }
);

export const deleteStaff = createAsyncThunk<any, string>(
  "role/deleteStaff",
  async (data: string, thunkAPI) => {
    try {
      return await staffService.deleteStaffById(data);
    } catch (error) {
      const axiosError = error as AxiosError;
      const data = axiosError.response?.data as ErrorResponse;
      const serverErrorMessage = data?.error ?? "Something bad went wrong";

      logging.error("Error deleting staff info:", serverErrorMessage);

      // Reject the thunk action with the server error message
      return thunkAPI.rejectWithValue({ message: serverErrorMessage });
    }
  }
);
