import React, { useEffect, useMemo, useState } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { usersSelectorLoadingState } from "../../../../selectors/userSelector";
// import { UserModel } from "../../../../@types/user-model";
import ReusableTable from "../../../../components/reusable-table";
import { CustomColumn } from "../../../../@types/table";
import { searchQuery } from "../../../../selectors/seacrhSelector";
import { resetSearch } from "../../../../store/slices/search-slice";
import { AppDispatch } from "../../../../store";
// import { WalletInfo } from "../../../../@types/wallet";
// import { useNavigate } from "react-router-dom";
import { RaffleTransactionModel } from "../../../../@types/raffle";
import dayjs from "dayjs";
import { raffleTransactions } from "../../../../selectors/raffleSelector";
import { Button } from "antd";
import { formatCurrency } from "../../../../utils";
import { Tabs, Tab } from "@mui/material";

const RafflePaymentPage = () => {
  const dispatch: AppDispatch = useDispatch();
  // const navigate = useNavigate();
  const raffleTransaction: RaffleTransactionModel[] | null =
    useSelector(raffleTransactions);
  const [sortDirection, setSortDirection] = useState("descending");

  const isLoading = useSelector(usersSelectorLoadingState);
  const search = useSelector(searchQuery);

  const theme = useTheme();

  const [activeTab, setActiveTab] = useState<number>(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    dispatch(resetSearch());
  }, [dispatch]);

  const columns: CustomColumn<RaffleTransactionModel>[] = useMemo(
    () => [
      { id: "userPhoneNumber", label: "User" },
      {
        id: "amount",
        label: "Amount (NGN)",
        format: (value) => formatCurrency(value),
      },
      {
        id: "channelID",
        label: "Channel",
      },
      {
        id: "numberOfEntry",
        label: "Entries",
      },

      {
        id: "status",
        label: "Status",
        format: (value: string) => getStatusColor(value),
      },
      {
        id: "raffleDate",
        label: "Date",
        format: (value: string) => dayjs(value).format("YYYY-MM-DD"), // assuming the string is in a recognizable date format
      },
    ],
    []
  );

  const getStatusColor = (value: string) => {
    const color =
      value.toLowerCase() === "successful" || value.toLowerCase() === "success"
        ? "green"
        : "red";
    return <Typography style={{ color: color }}>{value}</Typography>;
  };

  const sortedAndFilteredWallets = useMemo(() => {
    let sortableItems = raffleTransaction ? [...raffleTransaction] : [];
    if (search) {
      const searchLower = search.toLowerCase(); // Convert search term to lowercase once
      sortableItems = sortableItems.filter(
        (winner) =>
          winner.userPhoneNumber.toLowerCase().includes(searchLower) ||
          winner.channelID.toLowerCase().includes(searchLower) ||
          winner.amount.toString().toLowerCase().includes(searchLower)
      );
    }

    // Sorting logic
    sortableItems.sort((a, b) => {
      if (a.raffleDate < b.raffleDate) {
        return sortDirection === "ascending" ? -1 : 1;
      }
      if (a.raffleDate > b.raffleDate) {
        return sortDirection === "ascending" ? 1 : -1;
      }
      return 0;
    });

    return sortableItems;
  }, [raffleTransaction, search, sortDirection]);

  // Function to toggle sort direction
  const toggleSortDirection = () => {
    setSortDirection((prevDirection) =>
      prevDirection === "ascending" ? "descending" : "ascending"
    );
  };

  // const handleUserClick = (raffle: RaffleTicketModel) => {
  //   navigate(`/dashboard/raffles/${raffle.raffleID}`);
  // };

  const successfulTransactions = useMemo(() => {
    return sortedAndFilteredWallets.filter(
      (transaction) =>
        transaction.status.toLowerCase() === "successful" ||
        transaction.status.toLowerCase() === "success"
    );
  }, [sortedAndFilteredWallets]);

  const otherTransactions = useMemo(() => {
    return sortedAndFilteredWallets.filter(
      (transaction) =>
        transaction.status.toLowerCase() !== "successful" &&
        transaction.status.toLowerCase() !== "success"
    );
  }, [sortedAndFilteredWallets]);

  return (
    <Box padding={theme.spacing(3)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              sx={{ fontWeight: "bold" }}
            >
              Raffle Payments
            </Typography>
            <Button onClick={toggleSortDirection}>Toggle Date Sort</Button>
          </Box>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="Successful Payments" />
            <Tab label="Other Payments" />
          </Tabs>
        </Grid>
        {isLoading ? (
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            <Typography> Loading...</Typography>
          </Grid>
        ) : (
          <Grid item xs={12}>
            {activeTab === 0 ? (
              <ReusableTable
                data={successfulTransactions}
                columns={columns}
                paginationOptions={{
                  rowsPerPage: 20,
                  rowsPerPageOptions: [10, 20, 30],
                  showPagination: true,
                }}
                enableRowClick={true}
              />
            ) : (
              <ReusableTable
                data={otherTransactions}
                columns={columns}
                paginationOptions={{
                  rowsPerPage: 20,
                  rowsPerPageOptions: [10, 20, 30],
                  showPagination: true,
                }}
                enableRowClick={true}
              />
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default RafflePaymentPage;
